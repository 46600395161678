import { Order } from './order.model';

export class Subscription extends Order {

    billing_period: string;
    billing_interval: string;
    start_date: Date;
    trial_end_date: Date;
    next_payment_date: Date;
    end_date: Date;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      super(json);
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      super.loadWithJSON(json);
      this.billing_period = json.billing_period;
      this.billing_interval = json.billing_interval;
      this.start_date = json.start_date ? new Date(json.start_date):null;
      this.trial_end_date = json.trial_end_date ? new Date(json.trial_end_date):null;
      this.next_payment_date = json.next_payment_date ? new Date(json.next_payment_date):null;
      this.end_date = json.end_date ? new Date(json.end_date):null;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      let json = super.toJSON();
      json.billing_period = this.billing_period;
      json.billing_interval = this.billing_interval;
      json.start_date = this.start_date ? this.start_date.toISOString():null;
      json.trial_end_date = this.trial_end_date ? this.trial_end_date.toISOString():null;
      json.next_payment_date = this.next_payment_date ? this.next_payment_date.toISOString():null;
      json.end_date = this.end_date ? this.end_date.toISOString():null;
      return json;
    }

    get formattedNextDate():string {
      if (!this.next_payment_date) return '';
      return this.next_payment_date.toLocaleDateString('en-US', {day: 'numeric', month: 'long', year: 'numeric',});
    }

}
