export enum DataLayerCustomDimensions {
  customerBillingAddress1 = 'customerBillingAddress1',
  customerBillingAddress2 = 'customerBillingAddress2',
  customerBillingCity = 'customerBillingCity',
  customerBillingCompany = 'customerBillingCompany',
  customerBillingCountry = 'customerBillingCountry',
  customerBillingEmail = 'customerBillingEmail',
  customerBillingFirstName = 'customerBillingFirstName',
  customerBillingLastName = 'customerBillingLastName',
  customerBillingPhone = 'customerBillingPhone',
  customerBillingPostcode = 'customerBillingPostcode',
  customerFirstName = 'customerFirstName',
  customerLastName = 'customerLastName',
  customerShippingAddress1 = 'customerShippingAddress1',
  customerShippingAddress2 = 'customerShippingAddress2',
  customerShippingCity = 'customerShippingCity',
  customerShippingCompany = 'customerShippingCompany',
  customerShippingCountry = 'customerShippingCountry',
  customerShippingFirstName = 'customerShippingFirstName',
  customerShippingLastName = 'customerShippingLastName',
  customerShippingPostcode = 'customerShippingPostcode',
  ecomm_pagetype = 'ecomm_pagetype',
  ecomm_prodid = 'ecomm_prodid',
  ecomm_totalvalue = 'ecomm_totalvalue',
  pagePostType = 'pagePostType',
  pagePostType2 = 'pagePostType2',
  productIsVariable = 'productIsVariable',
  productType = 'productType',
  visitorEmail = 'visitorEmail',
  visitorId = 'visitorId',
  customerTotalOrderValue = "customerTotalOrderValue",
  customerTotalOrders = "customerTotalOrders",
  searchTerm = "searchTerm",
  email = "email",
  record_id = "record_id"
}
