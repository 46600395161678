import { PMDModel } from './pmdmodel.model';
import { Checkout } from './checkout.model';
import { Address } from './address.model';
import { Buffer } from 'buffer';

export class User implements PMDModel {
    // id: number;
    // email: string;
    // shipping_address: Address;
    // billing_address: Address;

    id: string;
    email: string;
    firstName: string;
    lastName: string;
    displayName: string;
    phone: string;
    defaultAddress: Address;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) return;

      this.id = json.id ? json.id : '';
      this.email = json.email ? json.email : '';
      this.firstName = json.firstName ? json.firstName : '';
      this.lastName = json.lastName ? json.lastName : '';
      this.displayName = json.displayName ? json.displayName : '';
      this.phone = json.phone ? json.phone : '';
      this.defaultAddress = json.defaultAddress ? new Address(json.defaultAddress) : new Address({});
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      var json: any = {
        'id': this.id,
        'email': this.email,
        'firstName': this.firstName,
        'lastName': this.lastName,
        'displayName': this.displayName,
        'phone': this.phone,
        'defaultAddress': this.defaultAddress ? this.defaultAddress.toJSON() : null,
      };
      // var json: any = {
      //   'id': this.id,
      //   'email': this.email,
      //   'shipping_address': this.shipping_address.toJSON(),
      //   'billing_address': this.billing_address.toJSON(),
      // };
      return json;
    }

    getDecodedId() {
      if (!this.id) return null;
      let id = Buffer.from(this.id, 'base64').toString();
      id = id.split('/').pop();
      return id
    }
}
