import { PMDModel } from './pmdmodel.model';
import { ProductImage } from './productimage.model';

export class ProductMedia implements PMDModel {
  id: string;
  mediaContentType: string;
  alt: string;
  embeddedUrl?: string;
  host?: string;
  image?: ProductImage;
  previewImage?: ProductImage;

  /**
   * Create a Call.
   * @param {any} json - The JSON object with which to construct our call.
   */
  constructor(json: any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Call.
   * @param {any} json - The JSON object with which to load our call.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.mediaContentType = json.mediaContentType;
    this.alt = json.alt;
    this.embeddedUrl = json.embeddedUrl;
    this.host = json.host;
    this.image = json.image && new ProductImage(json.image);
    this.previewImage = json.previewImage && new ProductImage(json.previewImage);
  }
  /**
   * Returns a JSON representation of our call
   * @return {any} - The JSON representation of our call
   */
  toJSON(): any {
    var json: any = {
      id: this.id,
      mediaContentType: this.mediaContentType,
      alt: this.alt,
      embeddedUrl: this.embeddedUrl,
      host: this.host,
      image: this.image?.toJSON(),
      previewImage: this.previewImage?.toJSON(),
    };
    return json;
  }
}
