import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  @Input()
  overlay = false;

  @Input()
  size: "regular" | "small" = "regular";

  constructor() { }

  ngOnInit() {

  }

  ngOnDestroy() {
  }

}
