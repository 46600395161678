import { PMDModel } from './pmdmodel.model';

export class ProductSelectedOption implements PMDModel {
    name: string;
    value: string;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }

      this.name = json.name;
      this.value = json.value;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      var json: any = {
        'name': this.name,
        'value': this.value,
      }
      return json;
    }
}
