import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag'; //needed by Apollo

import { Product } from '../shared/models/product.model';
import { ProductRequestParams } from '../shared/models/productrequestparams.model';
import { Observable, of , from} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { products } from './gql/products.gql';
import { ProductSwatch } from '../shared/models/productswatch.model';

export interface ProductResponse {
  products:Product[],
  hasNextPage:boolean,
  lastCursor: string
}


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private _transferState: TransferState,
    private _platform: Platform,
    private apollo: Apollo,
  ) { }

  getProducts(params:ProductRequestParams): Observable<ProductResponse> {

    let p = new HttpParams({ fromObject: params.toJSON() });
    // Check if products are cached from server
    const PRODUCTS_KEY = makeStateKey<{products: any[], hasNextPage: boolean, lastCursor: string}>('products-' + p.toString());
    if (this._transferState.hasKey(PRODUCTS_KEY)) {
      const products = this._transferState.get<{products: any[], hasNextPage: boolean, lastCursor: string}>(PRODUCTS_KEY, null);
      this._transferState.remove(PRODUCTS_KEY);
      return of ({products: products.products.map((p) => new Product(p)).filter((p) => !p.hidden), hasNextPage: products.hasNextPage,  lastCursor: products.lastCursor});
    }

    return this.apollo.query<any>({
      query: products,
      variables: {
        numProducts: params.per_page,
        cursor: params.afterCursor,
        filterStr: params.search ? params.search:'',
        sortKey: 'BEST_SELLING'
      },
    }).pipe(
      map(({data}) => {
        return data.products;
      }),
      tap(products => {
        if (!this._platform.isBrowser) {
          this._transferState.set(PRODUCTS_KEY, {products: products.edges.map(edge => edge.node), hasNextPage: products.pageInfo.hasNextPage, lastCursor: products.edges.length > 0 ? products.edges[products.edges.length-1].cursor:''});
        }
      }),
      map(products => {
        return {
          products: products.edges.map((edge) => new Product(edge.node)).filter((p) => !p.hidden),
          hasNextPage: products.pageInfo.hasNextPage,
          lastCursor: products.edges.length > 0 ? products.edges[products.edges.length - 1].cursor : '',
        };
      })
    );
  }


  
}
