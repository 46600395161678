import { PMDModel } from './pmdmodel.model';

export class ProductRequestParams implements PMDModel {
    per_page: number; //DEFAULT: 10
    search: string;
    afterCursor: string;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      this.per_page = json.per_page ? json.per_page : 12;
      if(json.search) this.search = json.search;
      if(json.afterCursor) this.afterCursor = json.afterCursor;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      var json: any = {
        'per_page': this.per_page,
      };
      if (this.search) json.search = this.search
      if (this.afterCursor) json.afterCursor = this.afterCursor
      return json;
    }
}
