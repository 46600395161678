import { PMDModel } from './pmdmodel.model';
import { environment } from '../../../environments/environment';

export class SubscriptionOption implements PMDModel {
  order_interval_frequency: number;
  order_interval_unit: string;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      this.order_interval_frequency = json.order_interval_frequency;
      this.order_interval_unit = json.order_interval_unit;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      var json: any = {
        "order_interval_frequency": this.order_interval_frequency,
        "order_interval_unit": this.order_interval_unit,
      };
      return json;
    }

    getDisplayName(price, discounted=false, subscription_discount=0):string {
      try {
        if(price == null || price == undefined || price == '')
        return '';
        let discount = 1 - subscription_discount / 100;
        let name = environment.currencySymbol + (discounted ? Number(price).toFixed(2) : Number(price * discount).toFixed(2));
        name += this.order_interval_frequency == 1 ? ' / ' + this.order_interval_unit.replace('s','') : ' every ' + this.order_interval_frequency + ' ' + this.order_interval_unit;
        return name;
      } catch(e) {
        console.log(e);
        return '';
      }
    }
}
