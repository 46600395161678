import { PMDModel } from './pmdmodel.model';

export class MoneyV2 implements PMDModel {
    amount: number;
    __typename: 'MoneyV2';

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      this.amount = json.amount;
      this.__typename = json.__typename;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON(): any {
      var json: any = {
        "amount": this.amount,
        "__typename": this.__typename,
      };
      return json;
    }
}
