/**
 * Schema generated at https://pmdb.myshopify.com/admin/apps/shopify-graphiql-app
 * and using following query in Admin API
 * 
 * {
 *  __schema {
 *    types {
 *      kind
 *      name
 *      possibleTypes {
 *        name
 *      }
 *    }
 *  }
 *}
 *
 * Based on documentation at https://www.apollographql.com/docs/react/data/fragments/#fragment-matcher
 * 
 */
export const schema = {
  "data": {
    "__schema": {
      "types": [
        {
          "kind": "SCALAR",
          "name": "ARN",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AccessScope",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AdjustmentSale",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AllDiscountItems",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ApiVersion",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "App",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppCredit",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppCreditConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppCreditCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppCreditEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppFeedback",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppInstallation",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppInstallationCategory",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppInstallationConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppInstallationEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppInstallationPrivacy",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppInstallationSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AppPlanInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppPlanV2",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "AppPricingDetails",
          "possibleTypes": [
            {
              "name": "AppRecurringPricing"
            },
            {
              "name": "AppUsagePricing"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "AppPricingInterval",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "AppPurchase",
          "possibleTypes": [
            {
              "name": "AppPurchaseOneTime"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "AppPurchaseOneTime",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppPurchaseOneTimeConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppPurchaseOneTimeCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppPurchaseOneTimeEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppPurchaseStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppRecurringPricing",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AppRecurringPricingInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppRevenueAttributionRecord",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppRevenueAttributionRecordConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppRevenueAttributionRecordCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppRevenueAttributionRecordCreateUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppRevenueAttributionRecordCreateUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppRevenueAttributionRecordDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppRevenueAttributionRecordDeleteUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppRevenueAttributionRecordDeleteUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppRevenueAttributionRecordEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AppRevenueAttributionRecordInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppRevenueAttributionRecordSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppRevenueAttributionType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppSubscription",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppSubscriptionCancelPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppSubscriptionConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppSubscriptionCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppSubscriptionEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppSubscriptionLineItem",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AppSubscriptionLineItemInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppSubscriptionLineItemUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppSubscriptionSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppSubscriptionStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppTransactionSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppUsagePricing",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AppUsagePricingInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppUsageRecord",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppUsageRecordConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppUsageRecordCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AppUsageRecordEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AppUsageRecordSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Attribute",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "AttributeInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "AutomaticDiscountApplication",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "AutomaticDiscountSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BasicEvent",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BillingAttemptUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "BillingAttemptUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Boolean",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "BulkMutationErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BulkMutationUserError",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BulkOperation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BulkOperationCancelPayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "BulkOperationErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BulkOperationRunMutationPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "BulkOperationRunQueryPayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "BulkOperationStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "BulkOperationType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedAutomaticDiscountApplication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedDiscountAllocation",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "CalculatedDiscountApplication",
          "possibleTypes": [
            {
              "name": "CalculatedAutomaticDiscountApplication"
            },
            {
              "name": "CalculatedDiscountCodeApplication"
            },
            {
              "name": "CalculatedManualDiscountApplication"
            },
            {
              "name": "CalculatedScriptDiscountApplication"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedDiscountApplicationConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedDiscountApplicationEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedDiscountCodeApplication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedDraftOrder",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedDraftOrderLineItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedLineItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedLineItemConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedLineItemEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedManualDiscountApplication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedOrder",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CalculatedScriptDiscountApplication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CaptureSession",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CaptureSessionRejectPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CaptureSessionRejectUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CaptureSessionRejectUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CaptureSessionRejectionReasonInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CaptureSessionResolvePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CaptureSessionResolveUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CaptureSessionResolveUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CaptureSessionStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CaptureSessionStatusCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CaptureSessionStatusReason",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CaptureSessionStatusReasonRejectionCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Channel",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ChannelConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ChannelEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CodeDiscountSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Collection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionAddProductsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CollectionDeleteInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CollectionInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionPublication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionPublicationConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionPublicationEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CollectionPublicationInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CollectionPublishInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionPublishPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionRemoveProductsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionReorderProductsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionRule",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CollectionRuleColumn",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionRuleConditions",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CollectionRuleInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CollectionRuleRelation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionRuleSet",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CollectionRuleSetInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CollectionSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CollectionSortOrder",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CollectionUnpublishInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionUnpublishPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CollectionUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CommentEvent",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CommentEventAttachment",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "CommentEventEmbed",
          "possibleTypes": [
            {
              "name": "Customer"
            },
            {
              "name": "DraftOrder"
            },
            {
              "name": "Order"
            },
            {
              "name": "Product"
            },
            {
              "name": "ProductVariant"
            }
          ]
        },
        {
          "kind": "INTERFACE",
          "name": "CommentEventSubject",
          "possibleTypes": [
            {
              "name": "Customer"
            },
            {
              "name": "DraftOrder"
            },
            {
              "name": "Order"
            },
            {
              "name": "PriceRule"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "CountriesInShippingZones",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CountryCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CountryHarmonizedSystemCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CountryHarmonizedSystemCodeConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CountryHarmonizedSystemCodeEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CountryHarmonizedSystemCodeInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CreateMediaInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CropRegion",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CurrencyCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CurrencyFormats",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CurrencySetting",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CurrencySettingConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CurrencySettingEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Customer",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerAddTaxExemptionsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerCreditCard",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerCreditCardBillingAddress",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CustomerDeleteInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerGenerateAccountActivationUrlPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CustomerInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerJourney",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerJourneySummary",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CustomerMarketingOptInLevel",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "CustomerMoment",
          "possibleTypes": [
            {
              "name": "CustomerVisit"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "CustomerMomentConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerMomentEdge",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "CustomerPaymentInstrument",
          "possibleTypes": [
            {
              "name": "CustomerCreditCard"
            },
            {
              "name": "CustomerPaypalBillingAgreement"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentInstrumentBillingAddress",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethod",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodCreditCardCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodCreditCardUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodPaypalBillingAgreementCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodPaypalBillingAgreementUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodRemoteCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodRemoteCreditCardCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "CustomerPaymentMethodRemoteInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodRemoteUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CustomerPaymentMethodRemoteUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodRevokePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodSendUpdateEmailPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaymentMethodUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CustomerPaymentMethodUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerPaypalBillingAgreement",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CustomerProductSubscriberStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerRemoveTaxExemptionsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerReplaceTaxExemptionsPayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CustomerSavedSearchSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CustomerSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "CustomerState",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerUpdateDefaultAddressPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "CustomerVisit",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Date",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "DateTime",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DayOfTheWeek",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Decimal",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeletionEvent",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeletionEventConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeletionEventEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DeletionEventSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DeletionEventSubjectType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryAvailableService",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryCarrierService",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryCarrierServiceAndLocations",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryCondition",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DeliveryConditionCriteria",
          "possibleTypes": [
            {
              "name": "MoneyV2"
            },
            {
              "name": "Weight"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "DeliveryConditionField",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DeliveryConditionOperator",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryCountry",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryCountryAndZone",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryCountryCodeOrRestOfWorld",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryCountryCodesOrRestOfWorld",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryCountryInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryLegacyModeBlocked",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DeliveryLegacyModeBlockedReason",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryLocationGroup",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryLocationGroupZone",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryLocationGroupZoneConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryLocationGroupZoneEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryLocationGroupZoneInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryMethod",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryMethodDefinition",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryMethodDefinitionConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryMethodDefinitionCounts",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryMethodDefinitionEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryMethodDefinitionInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DeliveryMethodDefinitionType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DeliveryMethodType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryParticipant",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryParticipantInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryParticipantService",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryParticipantServiceInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryPriceConditionInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryProductVariantsCount",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryProfile",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryProfileConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryProfileEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryProfileInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryProfileItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryProfileItemConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryProfileItemEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryProfileLocationGroup",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryProfileLocationGroupInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryProvince",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryProvinceInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryRateDefinition",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryRateDefinitionInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DeliveryRateProvider",
          "possibleTypes": [
            {
              "name": "DeliveryParticipant"
            },
            {
              "name": "DeliveryRateDefinition"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "DeliverySetting",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliverySettingInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliverySettingUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryShippingOriginAssignPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryUpdateConditionInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DeliveryWeightConditionInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DeliveryZone",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DigitalWallet",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAllocation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAmount",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountAmountInput",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "DiscountApplication",
          "possibleTypes": [
            {
              "name": "AutomaticDiscountApplication"
            },
            {
              "name": "DiscountCodeApplication"
            },
            {
              "name": "ManualDiscountApplication"
            },
            {
              "name": "ScriptDiscountApplication"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "DiscountApplicationAllocationMethod",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountApplicationConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountApplicationEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DiscountApplicationLevel",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DiscountApplicationTargetSelection",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DiscountApplicationTargetType",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DiscountAutomatic",
          "possibleTypes": [
            {
              "name": "DiscountAutomaticBasic"
            },
            {
              "name": "DiscountAutomaticBxgy"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticActivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticBasic",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticBasicCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountAutomaticBasicInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticBasicUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticBulkDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticBxgy",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticBxgyCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountAutomaticBxgyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticBxgyUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticDeactivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticNode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticNodeConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountAutomaticNodeEdge",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DiscountCode",
          "possibleTypes": [
            {
              "name": "DiscountCodeBasic"
            },
            {
              "name": "DiscountCodeBxgy"
            },
            {
              "name": "DiscountCodeFreeShipping"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeActivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeApplication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeBasic",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeBasicCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCodeBasicInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeBasicUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeBulkActivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeBulkDeactivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeBulkDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeBxgy",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeBxgyCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCodeBxgyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeBxgyUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeDeactivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeFreeShipping",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeFreeShippingCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCodeFreeShippingInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeFreeShippingUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeNode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeNodeConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeNodeEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCodeRedeemCodeBulkDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DiscountCodeSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCollections",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCollectionsInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCountries",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCountriesInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCountryAll",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCustomerAll",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCustomerBuys",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCustomerBuysInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DiscountCustomerBuysValue",
          "possibleTypes": [
            {
              "name": "DiscountPurchaseAmount"
            },
            {
              "name": "DiscountQuantity"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCustomerBuysValueInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCustomerGets",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCustomerGetsInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DiscountCustomerGetsValue",
          "possibleTypes": [
            {
              "name": "DiscountAmount"
            },
            {
              "name": "DiscountOnQuantity"
            },
            {
              "name": "DiscountPercentage"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCustomerGetsValueInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCustomerSavedSearches",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCustomerSavedSearchesInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DiscountCustomerSelection",
          "possibleTypes": [
            {
              "name": "DiscountCustomerAll"
            },
            {
              "name": "DiscountCustomerSavedSearches"
            },
            {
              "name": "DiscountCustomers"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCustomerSelectionInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountCustomers",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountCustomersInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DiscountEffect",
          "possibleTypes": [
            {
              "name": "DiscountPercentage"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountEffectInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DiscountErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DiscountItems",
          "possibleTypes": [
            {
              "name": "AllDiscountItems"
            },
            {
              "name": "DiscountCollections"
            },
            {
              "name": "DiscountProducts"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountItemsInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountMinimumQuantity",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountMinimumQuantityInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DiscountMinimumRequirement",
          "possibleTypes": [
            {
              "name": "DiscountMinimumQuantity"
            },
            {
              "name": "DiscountMinimumSubtotal"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountMinimumRequirementInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountMinimumSubtotal",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountMinimumSubtotalInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountOnQuantity",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountOnQuantityInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountPercentage",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountProducts",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountProductsInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountPurchaseAmount",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountQuantity",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountRedeemCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountRedeemCodeBulkAddPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountRedeemCodeBulkCreation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountRedeemCodeBulkCreationCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountRedeemCodeBulkCreationCodeConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountRedeemCodeBulkCreationCodeEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountRedeemCodeConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountRedeemCodeEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountRedeemCodeInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountShareableUrl",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DiscountShareableUrlTargetType",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "DiscountShippingDestinationSelection",
          "possibleTypes": [
            {
              "name": "DiscountCountries"
            },
            {
              "name": "DiscountCountryAll"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DiscountShippingDestinationSelectionInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DiscountStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DiscountTargetType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DiscountType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DiscountUserError",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "DisplayableError",
          "possibleTypes": [
            {
              "name": "AppRevenueAttributionRecordCreateUserError"
            },
            {
              "name": "AppRevenueAttributionRecordDeleteUserError"
            },
            {
              "name": "BillingAttemptUserError"
            },
            {
              "name": "BulkMutationUserError"
            },
            {
              "name": "CaptureSessionRejectUserError"
            },
            {
              "name": "CaptureSessionResolveUserError"
            },
            {
              "name": "CustomerPaymentMethodRemoteUserError"
            },
            {
              "name": "CustomerPaymentMethodUserError"
            },
            {
              "name": "DiscountUserError"
            },
            {
              "name": "FilesUserError"
            },
            {
              "name": "FulfillmentOrderRescheduleUserError"
            },
            {
              "name": "GiftCardUserError"
            },
            {
              "name": "MediaUserError"
            },
            {
              "name": "PriceListPriceUserError"
            },
            {
              "name": "PriceListUserError"
            },
            {
              "name": "PriceRuleUserError"
            },
            {
              "name": "ProductChangeStatusUserError"
            },
            {
              "name": "ProductVariantsBulkCreateUserError"
            },
            {
              "name": "ProductVariantsBulkDeleteUserError"
            },
            {
              "name": "ProductVariantsBulkReorderUserError"
            },
            {
              "name": "ProductVariantsBulkUpdateUserError"
            },
            {
              "name": "PubSubWebhookSubscriptionCreateUserError"
            },
            {
              "name": "PubSubWebhookSubscriptionUpdateUserError"
            },
            {
              "name": "RefundSessionRejectUserError"
            },
            {
              "name": "RefundSessionResolveUserError"
            },
            {
              "name": "SellingPlanGroupUserError"
            },
            {
              "name": "ShopPolicyUserError"
            },
            {
              "name": "SubscriptionContractUserError"
            },
            {
              "name": "SubscriptionDraftUserError"
            },
            {
              "name": "TranslationUserError"
            },
            {
              "name": "UrlRedirectBulkDeleteByIdsUserError"
            },
            {
              "name": "UrlRedirectBulkDeleteBySavedSearchUserError"
            },
            {
              "name": "UrlRedirectBulkDeleteBySearchUserError"
            },
            {
              "name": "UrlRedirectImportUserError"
            },
            {
              "name": "UrlRedirectUserError"
            },
            {
              "name": "UserError"
            },
            {
              "name": "VoidSessionRejectUserError"
            },
            {
              "name": "VoidSessionResolveUserError"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "DisputeStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DisputeType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Domain",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DomainLocalization",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftFulfillment",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrder",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderAppliedDiscount",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DraftOrderAppliedDiscountInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DraftOrderAppliedDiscountType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderCalculatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderCompletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DraftOrderDeleteInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DraftOrderInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderInvoicePreviewPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderInvoiceSendPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderLineItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderLineItemConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderLineItemEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "DraftOrderLineItemInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DraftOrderSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "DraftOrderStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DraftOrderUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Duty",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "DutySale",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "EditableProperty",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "EmailInput",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "Event",
          "possibleTypes": [
            {
              "name": "BasicEvent"
            },
            {
              "name": "CommentEvent"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "EventBridgeWebhookSubscriptionCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "EventBridgeWebhookSubscriptionInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "EventBridgeWebhookSubscriptionUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "EventConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "EventEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "EventSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ExternalVideo",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FailedRequirement",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "File",
          "possibleTypes": [
            {
              "name": "GenericFile"
            },
            {
              "name": "MediaImage"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "FileConnection",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FileContentType",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "FileCreateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FileCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FileDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FileEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FileError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FileErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FileSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FileStatus",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "FileUpdateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FileUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FilesErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FilesUserError",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FilterOption",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Float",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FlowTriggerReceivePayload",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "FormattedString",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Fulfillment",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentCancelPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentCreateV2Payload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentDisplayStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentEvent",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentEventConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentEventEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentEventSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentEventStatus",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "FulfillmentInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentLineItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentLineItemConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentLineItemEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "FulfillmentLineItemInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrder",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderAcceptCancellationRequestPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderAcceptFulfillmentRequestPayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentOrderAction",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderAssignedLocation",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentOrderAssignmentStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderCancelPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderClosePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderDestination",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderInternationalDuties",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderLineItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderLineItemConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderLineItemEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "FulfillmentOrderLineItemInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "FulfillmentOrderLineItemsInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderLocationForMove",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderLocationForMoveConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderLocationForMoveEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderMerchantRequest",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderMerchantRequestConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderMerchantRequestEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentOrderMerchantRequestKind",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderMovePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderOpenPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderRejectCancellationRequestPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderRejectFulfillmentRequestPayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentOrderRequestStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderReschedulePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderRescheduleUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentOrderRescheduleUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentOrderSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentOrderStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderSubmitCancellationRequestPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderSubmitFulfillmentRequestPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentOrderSupportedAction",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentService",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentServiceCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentServiceDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentServiceType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentServiceUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "FulfillmentStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentTrackingInfo",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentTrackingInfoUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "FulfillmentTrackingInfoUpdateV2Payload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "FulfillmentTrackingInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "FulfillmentV2Input",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GenericFile",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GiftCard",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GiftCardConnection",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "GiftCardCreateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GiftCardCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GiftCardDisablePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GiftCardEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "GiftCardErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GiftCardSale",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "GiftCardSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "GiftCardUpdateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GiftCardUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "GiftCardUserError",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "HTML",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "HasEvents",
          "possibleTypes": [
            {
              "name": "Customer"
            },
            {
              "name": "DiscountAutomaticBxgy"
            },
            {
              "name": "DiscountAutomaticNode"
            },
            {
              "name": "DiscountCodeNode"
            },
            {
              "name": "DraftOrder"
            },
            {
              "name": "Order"
            },
            {
              "name": "PriceRule"
            }
          ]
        },
        {
          "kind": "INTERFACE",
          "name": "HasLocalizationExtensions",
          "possibleTypes": [
            {
              "name": "Order"
            }
          ]
        },
        {
          "kind": "INTERFACE",
          "name": "HasLocalizationExtensionsForDraftOrders",
          "possibleTypes": [
            {
              "name": "DraftOrder"
            }
          ]
        },
        {
          "kind": "INTERFACE",
          "name": "HasMetafields",
          "possibleTypes": [
            {
              "name": "Collection"
            },
            {
              "name": "Customer"
            },
            {
              "name": "DraftOrder"
            },
            {
              "name": "Image"
            },
            {
              "name": "Order"
            },
            {
              "name": "Product"
            },
            {
              "name": "ProductVariant"
            },
            {
              "name": "Shop"
            }
          ]
        },
        {
          "kind": "INTERFACE",
          "name": "HasPublishedTranslations",
          "possibleTypes": [
            {
              "name": "Collection"
            },
            {
              "name": "Link"
            },
            {
              "name": "OnlineStoreArticle"
            },
            {
              "name": "OnlineStoreBlog"
            },
            {
              "name": "OnlineStorePage"
            },
            {
              "name": "Product"
            },
            {
              "name": "ProductOption"
            },
            {
              "name": "ProductVariant"
            },
            {
              "name": "Shop"
            },
            {
              "name": "ShopPolicy"
            }
          ]
        },
        {
          "kind": "SCALAR",
          "name": "ID",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Image",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ImageConnection",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ImageContentType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ImageEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ImageInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ImageUploadParameter",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Int",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryActivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "InventoryAdjustItemInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "InventoryAdjustQuantityInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryAdjustQuantityPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryBulkAdjustQuantityAtLocationPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryDeactivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryItemConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryItemEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "InventoryItemInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "InventoryItemUpdateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryItemUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryLevel",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryLevelConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "InventoryLevelEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "InventoryLevelInput",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "JSON",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Job",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "LegacyInteroperability",
          "possibleTypes": [
            {
              "name": "Customer"
            },
            {
              "name": "DraftOrder"
            },
            {
              "name": "Fulfillment"
            },
            {
              "name": "InventoryItem"
            },
            {
              "name": "Location"
            },
            {
              "name": "MarketingEvent"
            },
            {
              "name": "Metafield"
            },
            {
              "name": "MetafieldStorefrontVisibility"
            },
            {
              "name": "Order"
            },
            {
              "name": "PriceRule"
            },
            {
              "name": "Product"
            },
            {
              "name": "ProductVariant"
            },
            {
              "name": "Refund"
            },
            {
              "name": "SavedSearch"
            },
            {
              "name": "ScriptTag"
            },
            {
              "name": "ShopifyPaymentsDispute"
            },
            {
              "name": "ShopifyPaymentsPayout"
            },
            {
              "name": "WebhookSubscription"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "LimitedPendingOrderCount",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LineItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LineItemConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LineItemEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LineItemMutable",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LineItemMutableConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LineItemMutableEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LineItemSellingPlan",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Link",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Locale",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LocalizationExtension",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LocalizationExtensionConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LocalizationExtensionEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "LocalizationExtensionInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "LocalizationExtensionKey",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "LocalizationExtensionPurpose",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Location",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LocationAddress",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LocationConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LocationEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "LocationSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "LocationSuggestedAddress",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MailingAddress",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MailingAddressInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ManualDiscountApplication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingActivity",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MarketingActivityBudgetInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingActivityConnection",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MarketingActivityCreateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingActivityCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingActivityEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MarketingActivityExtensionAppErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingActivityExtensionAppErrors",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MarketingActivitySortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MarketingActivityStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MarketingActivityStatusBadgeType",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MarketingActivityUpdateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingActivityUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingBudget",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MarketingBudgetBudgetType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MarketingChannel",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingEngagement",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingEngagementCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MarketingEngagementInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingEvent",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingEventConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MarketingEventEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MarketingEventSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MarketingTactic",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "Media",
          "possibleTypes": [
            {
              "name": "ExternalVideo"
            },
            {
              "name": "MediaImage"
            },
            {
              "name": "Model3d"
            },
            {
              "name": "Video"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "MediaConnection",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MediaContentType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MediaEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MediaError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MediaErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MediaHost",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MediaImage",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MediaPreviewImage",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MediaPreviewImageStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MediaStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MediaUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MediaUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Metafield",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MetafieldConnection",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MetafieldDeleteInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MetafieldDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MetafieldEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MetafieldInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MetafieldOwnerType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MetafieldStorefrontVisibility",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MetafieldStorefrontVisibilityConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MetafieldStorefrontVisibilityCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MetafieldStorefrontVisibilityDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MetafieldStorefrontVisibilityEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MetafieldStorefrontVisibilityInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MetafieldValueType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "MethodDefinitionSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Model3d",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Model3dSource",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "Money",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MoneyBag",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MoneyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MoneyV2",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "MoveInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Mutation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "MutationsStagedUploadTargetGenerateUploadParameter",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "Navigable",
          "possibleTypes": [
            {
              "name": "OnlineStoreArticle"
            },
            {
              "name": "OnlineStorePage"
            },
            {
              "name": "Product"
            },
            {
              "name": "ProductVariant"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "NavigationItem",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "Node",
          "possibleTypes": [
            {
              "name": "App"
            },
            {
              "name": "AppCredit"
            },
            {
              "name": "AppInstallation"
            },
            {
              "name": "AppPurchaseOneTime"
            },
            {
              "name": "AppRevenueAttributionRecord"
            },
            {
              "name": "AppSubscription"
            },
            {
              "name": "AppUsageRecord"
            },
            {
              "name": "BasicEvent"
            },
            {
              "name": "BulkOperation"
            },
            {
              "name": "CalculatedOrder"
            },
            {
              "name": "Channel"
            },
            {
              "name": "Collection"
            },
            {
              "name": "CommentEvent"
            },
            {
              "name": "Customer"
            },
            {
              "name": "CustomerPaymentMethod"
            },
            {
              "name": "CustomerVisit"
            },
            {
              "name": "DeliveryCarrierService"
            },
            {
              "name": "DeliveryCondition"
            },
            {
              "name": "DeliveryCountry"
            },
            {
              "name": "DeliveryLocationGroup"
            },
            {
              "name": "DeliveryMethod"
            },
            {
              "name": "DeliveryMethodDefinition"
            },
            {
              "name": "DeliveryParticipant"
            },
            {
              "name": "DeliveryProfile"
            },
            {
              "name": "DeliveryProvince"
            },
            {
              "name": "DeliveryRateDefinition"
            },
            {
              "name": "DeliveryZone"
            },
            {
              "name": "DiscountAutomaticBxgy"
            },
            {
              "name": "DiscountAutomaticNode"
            },
            {
              "name": "DiscountCodeNode"
            },
            {
              "name": "DiscountRedeemCodeBulkCreation"
            },
            {
              "name": "Domain"
            },
            {
              "name": "DraftOrder"
            },
            {
              "name": "DraftOrderLineItem"
            },
            {
              "name": "Duty"
            },
            {
              "name": "ExternalVideo"
            },
            {
              "name": "Fulfillment"
            },
            {
              "name": "FulfillmentEvent"
            },
            {
              "name": "FulfillmentLineItem"
            },
            {
              "name": "FulfillmentOrder"
            },
            {
              "name": "FulfillmentOrderDestination"
            },
            {
              "name": "FulfillmentOrderLineItem"
            },
            {
              "name": "FulfillmentOrderMerchantRequest"
            },
            {
              "name": "GenericFile"
            },
            {
              "name": "GiftCard"
            },
            {
              "name": "InventoryItem"
            },
            {
              "name": "InventoryLevel"
            },
            {
              "name": "LineItem"
            },
            {
              "name": "LineItemMutable"
            },
            {
              "name": "Location"
            },
            {
              "name": "MailingAddress"
            },
            {
              "name": "MarketingActivity"
            },
            {
              "name": "MarketingEvent"
            },
            {
              "name": "MediaImage"
            },
            {
              "name": "Metafield"
            },
            {
              "name": "MetafieldStorefrontVisibility"
            },
            {
              "name": "Model3d"
            },
            {
              "name": "OnlineStoreArticle"
            },
            {
              "name": "OnlineStoreBlog"
            },
            {
              "name": "OnlineStorePage"
            },
            {
              "name": "Order"
            },
            {
              "name": "OrderDisputeSummary"
            },
            {
              "name": "OrderTransaction"
            },
            {
              "name": "PriceList"
            },
            {
              "name": "PriceRule"
            },
            {
              "name": "PriceRuleDiscountCode"
            },
            {
              "name": "PrivateMetafield"
            },
            {
              "name": "Product"
            },
            {
              "name": "ProductOption"
            },
            {
              "name": "ProductVariant"
            },
            {
              "name": "Publication"
            },
            {
              "name": "Refund"
            },
            {
              "name": "SavedSearch"
            },
            {
              "name": "ScriptTag"
            },
            {
              "name": "SellingPlan"
            },
            {
              "name": "SellingPlanGroup"
            },
            {
              "name": "Shop"
            },
            {
              "name": "ShopPolicy"
            },
            {
              "name": "ShopifyPaymentsAccount"
            },
            {
              "name": "ShopifyPaymentsBankAccount"
            },
            {
              "name": "ShopifyPaymentsDispute"
            },
            {
              "name": "ShopifyPaymentsPayout"
            },
            {
              "name": "ShopifyPaymentsVerification"
            },
            {
              "name": "StorefrontAccessToken"
            },
            {
              "name": "SubscriptionBillingAttempt"
            },
            {
              "name": "SubscriptionContract"
            },
            {
              "name": "SubscriptionDraft"
            },
            {
              "name": "TenderTransaction"
            },
            {
              "name": "TransactionFee"
            },
            {
              "name": "UrlRedirect"
            },
            {
              "name": "UrlRedirectImport"
            },
            {
              "name": "Video"
            },
            {
              "name": "WebhookSubscription"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "OnlineStoreArticle",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OnlineStoreBlog",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OnlineStorePage",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "OnlineStorePreviewable",
          "possibleTypes": [
            {
              "name": "Product"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "Order",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "OrderActionType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderAgreement",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "OrderCancelReason",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OrderCaptureInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderCapturePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OrderCloseInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderClosePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderConnection",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "OrderDisplayFinancialStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "OrderDisplayFulfillmentStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderDisputeSummary",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderEditAddCustomItemPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderEditAddLineItemDiscountPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderEditAddVariantPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderEditAgreement",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OrderEditAppliedDiscountInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderEditBeginPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderEditCommitPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderEditRemoveLineItemDiscountPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderEditSetQuantityPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OrderInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OrderMarkAsPaidInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderMarkAsPaidPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OrderOpenInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderOpenPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderPaymentCollectionDetails",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderRisk",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "OrderRiskLevel",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "OrderSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "OrderStagedChange",
          "possibleTypes": [
            {
              "name": "OrderStagedChangeAddCustomItem"
            },
            {
              "name": "OrderStagedChangeAddLineItemDiscount"
            },
            {
              "name": "OrderStagedChangeAddShippingLine"
            },
            {
              "name": "OrderStagedChangeAddVariant"
            },
            {
              "name": "OrderStagedChangeDecrementItem"
            },
            {
              "name": "OrderStagedChangeIncrementItem"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "OrderStagedChangeAddCustomItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderStagedChangeAddLineItemDiscount",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderStagedChangeAddShippingLine",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderStagedChangeAddVariant",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderStagedChangeConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderStagedChangeDecrementItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderStagedChangeEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderStagedChangeIncrementItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderTransaction",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderTransactionConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderTransactionEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "OrderTransactionErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "OrderTransactionInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "OrderTransactionKind",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "OrderTransactionStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "OrderUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PageInfo",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PaymentMethods",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PaymentSession",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PaymentSessionActionsRedirect",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PaymentSessionNextAction",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PaymentSessionNextActionAction",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "PaymentSessionNextActionContext",
          "possibleTypes": [
            {
              "name": "PaymentSessionActionsRedirect"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "PaymentSessionRejectPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PaymentSessionRejectionReasonInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PaymentSessionResolvePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PaymentSessionStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PaymentSessionStatusCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PaymentSessionStatusReason",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PaymentSessionStatusReasonRejectionCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PaymentSettings",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PaymentsAppConfiguration",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PaymentsAppConfigurePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PaypalExpressSubscriptionsGatewayStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceList",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListAdjustment",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceListAdjustmentInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceListAdjustmentType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListConnection",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceListContext",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListContextRule",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceListContextRuleInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceListCreateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListFixedPricesAddPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListFixedPricesDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListParent",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceListParentCreateInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceListParentUpdateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListPrice",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListPriceConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListPriceEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceListPriceInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceListPriceOriginType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListPriceUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceListPriceUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceListSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceListUpdateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceListUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceListUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRule",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleActivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceRuleAllocationMethod",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleCustomerSelection",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleCustomerSelectionInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleDeactivatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleDiscountCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleDiscountCodeConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleDiscountCodeCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleDiscountCodeEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleDiscountCodeInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleDiscountCodeUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleEntitlementToPrerequisiteQuantityRatio",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleEntitlementToPrerequisiteQuantityRatioInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceRuleErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceRuleFeature",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleFixedAmountValue",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleItemEntitlements",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleItemEntitlementsInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleItemPrerequisitesInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleLineItemPrerequisites",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleMoneyRange",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleMoneyRangeInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRulePercentValue",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRulePrerequisiteToEntitlementQuantityRatio",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRulePrerequisiteToEntitlementQuantityRatioInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleQuantityRange",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleQuantityRangeInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleShareableUrl",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceRuleShareableUrlTargetType",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleShippingEntitlementsInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleShippingLineEntitlements",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceRuleSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceRuleStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceRuleTarget",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PriceRuleTrait",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleUserError",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PriceRuleValidityPeriod",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleValidityPeriodInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "PriceRuleValue",
          "possibleTypes": [
            {
              "name": "PriceRuleFixedAmountValue"
            },
            {
              "name": "PriceRulePercentValue"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PriceRuleValueInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PricingPercentageValue",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "PricingValue",
          "possibleTypes": [
            {
              "name": "MoneyV2"
            },
            {
              "name": "PricingPercentageValue"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "PrivateMetafield",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PrivateMetafieldConnection",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PrivateMetafieldDeleteInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PrivateMetafieldDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PrivateMetafieldEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PrivateMetafieldInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PrivateMetafieldUpsertPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PrivateMetafieldValueInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PrivateMetafieldValueType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Product",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductAppendImagesInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductAppendImagesPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductChangeStatusPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductChangeStatusUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductChangeStatusUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductCollectionSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductCreateMediaPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductDeleteImagesPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductDeleteInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductDeleteMediaPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductDuplicatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductImageSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductImageUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductJoinSellingPlanGroupsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductLeaveSellingPlanGroupsPayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductMediaSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductOption",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductPriceRange",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductPriceRangeV2",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductPublication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductPublicationConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductPublicationEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductPublicationInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductPublishInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductPublishPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductReorderImagesPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductReorderMediaPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductSale",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductStatus",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductUnpublishInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductUnpublishPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductUpdateMediaPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariant",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductVariantAppendMediaInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantAppendMediaPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductVariantDetachMediaInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantDetachMediaPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductVariantInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductVariantInventoryManagement",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductVariantInventoryPolicy",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantJoinSellingPlanGroupsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantLeaveSellingPlanGroupsPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductVariantPositionInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantPricePair",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantPricePairConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantPricePairEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductVariantSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantsBulkCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantsBulkCreateUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductVariantsBulkCreateUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantsBulkDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantsBulkDeleteUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductVariantsBulkDeleteUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ProductVariantsBulkInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantsBulkReorderPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantsBulkReorderUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductVariantsBulkReorderUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantsBulkUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ProductVariantsBulkUpdateUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProductVariantsBulkUpdateUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ProfileItemSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PubSubWebhookSubscriptionCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PubSubWebhookSubscriptionCreateUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PubSubWebhookSubscriptionCreateUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PubSubWebhookSubscriptionInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PubSubWebhookSubscriptionUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PubSubWebhookSubscriptionUpdateUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "PubSubWebhookSubscriptionUpdateUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Publication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicationConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublicationEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "PublicationInput",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "Publishable",
          "possibleTypes": [
            {
              "name": "Collection"
            },
            {
              "name": "Product"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "PublishablePublishPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublishablePublishToCurrentChannelPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublishableUnpublishPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublishableUnpublishToCurrentChannelPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "PublishedTranslation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "QueryRoot",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Refund",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundAgreement",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundDuty",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RefundDutyInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "RefundDutyRefundType",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RefundInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundLineItem",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundLineItemConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundLineItemEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RefundLineItemInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "RefundLineItemRestockType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundSession",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundSessionRejectPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundSessionRejectUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "RefundSessionRejectUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RefundSessionRejectionReasonInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundSessionResolvePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundSessionResolveUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "RefundSessionResolveUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundSessionStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "RefundSessionStatusCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "RefundSessionStatusReason",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "RefundSessionStatusReasonRejectionCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RemoteAuthorizeNetCustomerPaymentProfileInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "RemoteStripePaymentMethodInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourceAlert",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourceAlertAction",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ResourceAlertIcon",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ResourceAlertSeverity",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourceFeedback",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourceLimit",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourcePublication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourcePublicationConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourcePublicationEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourcePublicationV2",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourcePublicationV2Connection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ResourcePublicationV2Edge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SEO",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SEOInput",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "Sale",
          "possibleTypes": [
            {
              "name": "AdjustmentSale"
            },
            {
              "name": "DutySale"
            },
            {
              "name": "GiftCardSale"
            },
            {
              "name": "ProductSale"
            },
            {
              "name": "ShippingLineSale"
            },
            {
              "name": "TipSale"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "SaleActionType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SaleConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SaleEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SaleLineType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SaleTax",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "SalesAgreement",
          "possibleTypes": [
            {
              "name": "OrderAgreement"
            },
            {
              "name": "OrderEditAgreement"
            },
            {
              "name": "RefundAgreement"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "SalesAgreementConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SalesAgreementEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SavedSearch",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SavedSearchConnection",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SavedSearchCreateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SavedSearchCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SavedSearchDeleteInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SavedSearchDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SavedSearchEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SavedSearchUpdateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SavedSearchUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ScriptDiscountApplication",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ScriptTag",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ScriptTagConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ScriptTagCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ScriptTagDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ScriptTagDisplayScope",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ScriptTagEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ScriptTagInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ScriptTagUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SearchFilter",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SearchFilterOptions",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SearchResult",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SearchResultConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SearchResultEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SearchResultType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SelectedOption",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlan",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanAnchor",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanAnchorInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SellingPlanAnchorType",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "SellingPlanBillingPolicy",
          "possibleTypes": [
            {
              "name": "SellingPlanRecurringBillingPolicy"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanBillingPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanConnection",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "SellingPlanDeliveryPolicy",
          "possibleTypes": [
            {
              "name": "SellingPlanRecurringDeliveryPolicy"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanDeliveryPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanFixedPricingPolicy",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanFixedPricingPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroup",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupAddProductVariantsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupAddProductsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanGroupInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupRemoveProductVariantsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupRemoveProductsPayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanGroupResourceInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SellingPlanGroupSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanGroupUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SellingPlanGroupUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SellingPlanInterval",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "SellingPlanPricingPolicy",
          "possibleTypes": [
            {
              "name": "SellingPlanFixedPricingPolicy"
            },
            {
              "name": "SellingPlanRecurringPricingPolicy"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "SellingPlanPricingPolicyAdjustmentType",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "SellingPlanPricingPolicyAdjustmentValue",
          "possibleTypes": [
            {
              "name": "MoneyV2"
            },
            {
              "name": "SellingPlanPricingPolicyPercentageValue"
            }
          ]
        },
        {
          "kind": "INTERFACE",
          "name": "SellingPlanPricingPolicyBase",
          "possibleTypes": [
            {
              "name": "SellingPlanFixedPricingPolicy"
            },
            {
              "name": "SellingPlanRecurringPricingPolicy"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanPricingPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanPricingPolicyPercentageValue",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanPricingPolicyValueInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanRecurringBillingPolicy",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanRecurringBillingPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanRecurringDeliveryPolicy",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanRecurringDeliveryPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SellingPlanRecurringDeliveryPolicyIntent",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SellingPlanRecurringDeliveryPolicyPreAnchorBehavior",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SellingPlanRecurringPricingPolicy",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SellingPlanRecurringPricingPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingLine",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingLineConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingLineEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ShippingLineInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingLineSale",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingMethod",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingPackageDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingPackageMakeDefaultPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingPackageUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingRate",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShippingRefund",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ShippingRefundInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Shop",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopAlert",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopAlertAction",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopBranding",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopCustomerAccountsSetting",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopFeatures",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopLocale",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopLocaleDisablePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopLocaleEnablePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ShopLocaleInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopLocaleUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopPlan",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopPolicy",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopPolicyErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "ShopPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopPolicyType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopPolicyUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopPolicyUserError",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopResourceLimits",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopTagSort",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsAccount",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsBankAccount",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsBankAccountConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsBankAccountEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopifyPaymentsBankAccountStatus",
          "possibleTypes": null
        },
        {
          "kind": "INTERFACE",
          "name": "ShopifyPaymentsChargeStatementDescriptor",
          "possibleTypes": [
            {
              "name": "ShopifyPaymentsDefaultChargeStatementDescriptor"
            },
            {
              "name": "ShopifyPaymentsJpChargeStatementDescriptor"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsDefaultChargeStatementDescriptor",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsDispute",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsDisputeConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsDisputeEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopifyPaymentsDisputeReason",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsDisputeReasonDetails",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsExtendedAuthorization",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsFraudSettings",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsJpChargeStatementDescriptor",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsNotificationSettings",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsPayout",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsPayoutConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsPayoutEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopifyPaymentsPayoutInterval",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsPayoutSchedule",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopifyPaymentsPayoutStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsPayoutSummary",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopifyPaymentsPayoutTransactionType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsTransactionSet",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsVerification",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsVerificationDocument",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopifyPaymentsVerificationDocumentType",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "ShopifyPaymentsVerificationStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "ShopifyPaymentsVerificationSubject",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "StageImageInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StagedMediaUploadTarget",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "StagedUploadHttpMethodType",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "StagedUploadInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StagedUploadParameter",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StagedUploadTarget",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "StagedUploadTargetGenerateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StagedUploadTargetGeneratePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "StagedUploadTargetGenerateUploadResource",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StagedUploadTargetsGeneratePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StagedUploadsCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StorefrontAccessToken",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StorefrontAccessTokenConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StorefrontAccessTokenCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "StorefrontAccessTokenDeleteInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StorefrontAccessTokenDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StorefrontAccessTokenEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "StorefrontAccessTokenInput",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "StorefrontID",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "String",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StringConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "StringEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionAppliedCodeDiscount",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionBillingAttempt",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionBillingAttemptConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionBillingAttemptCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionBillingAttemptEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SubscriptionBillingAttemptErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionBillingAttemptInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionBillingPolicy",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionBillingPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionContract",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionContractConnection",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionContractCreateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionContractCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionContractEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SubscriptionContractErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SubscriptionContractLastPaymentStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionContractSetNextBillingDatePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SubscriptionContractSubscriptionStatus",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionContractUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionContractUserError",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionCyclePriceAdjustment",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "SubscriptionDeliveryMethod",
          "possibleTypes": [
            {
              "name": "SubscriptionDeliveryMethodShipping"
            }
          ]
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionDeliveryMethodInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDeliveryMethodShipping",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionDeliveryMethodShippingInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDeliveryMethodShippingOption",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionDeliveryMethodShippingOptionInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDeliveryPolicy",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionDeliveryPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "SubscriptionDiscount",
          "possibleTypes": [
            {
              "name": "SubscriptionAppliedCodeDiscount"
            },
            {
              "name": "SubscriptionManualDiscount"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDiscountAllocation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDiscountConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDiscountEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDiscountEntitledLines",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDiscountFixedAmountValue",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDiscountPercentageValue",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SubscriptionDiscountRejectionReason",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "SubscriptionDiscountValue",
          "possibleTypes": [
            {
              "name": "SubscriptionDiscountFixedAmountValue"
            },
            {
              "name": "SubscriptionDiscountPercentageValue"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraft",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftCommitPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftDiscountAddPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftDiscountCodeApplyPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftDiscountRemovePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftDiscountUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SubscriptionDraftErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftFreeShippingDiscountAddPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftFreeShippingDiscountUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionDraftInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftLineAddPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftLineRemovePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftLineUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionDraftUserError",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionFreeShippingDiscountInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionLine",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionLineConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionLineEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionLineInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionLineUpdateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionMailingAddress",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionManualDiscount",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionManualDiscountConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionManualDiscountEdge",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionManualDiscountEntitledLinesInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionManualDiscountFixedAmountInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionManualDiscountInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionManualDiscountLinesInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionManualDiscountValueInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionPricingPolicy",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionPricingPolicyCycleDiscountsInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "SubscriptionPricingPolicyInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionShippingOption",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "SubscriptionShippingOptionResult",
          "possibleTypes": [
            {
              "name": "SubscriptionShippingOptionResultFailure"
            },
            {
              "name": "SubscriptionShippingOptionResultSuccess"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionShippingOptionResultFailure",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SubscriptionShippingOptionResultSuccess",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SuggestedOrderTransaction",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "SuggestedOrderTransactionKind",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "SuggestedRefund",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TagsAddPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TagsRemovePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "TaxExemption",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TaxLine",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TenderTransaction",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TenderTransactionConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TenderTransactionCreditCardDetails",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "TenderTransactionDetails",
          "possibleTypes": [
            {
              "name": "TenderTransactionCreditCardDetails"
            }
          ]
        },
        {
          "kind": "OBJECT",
          "name": "TenderTransactionEdge",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TipSale",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "TrackingInfoInput",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "TrackingInfoUpdateInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TransactionFee",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TranslatableContent",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TranslatableResource",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TranslatableResourceConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TranslatableResourceEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "TranslatableResourceType",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Translation",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "TranslationErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "TranslationInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TranslationUserError",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TranslationsRegisterPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "TranslationsRemovePayload",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "URL",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UTMInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UTMParameters",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "UnitSystem",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "UnsignedInt64",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UpdateMediaInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirect",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectBulkDeleteAllPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectBulkDeleteByIdsPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectBulkDeleteByIdsUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "UrlRedirectBulkDeleteByIdsUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectBulkDeleteBySavedSearchPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectBulkDeleteBySavedSearchUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "UrlRedirectBulkDeleteBySavedSearchUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectBulkDeleteBySearchPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectBulkDeleteBySearchUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "UrlRedirectBulkDeleteBySearchUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectEdge",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "UrlRedirectErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectImport",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectImportCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "UrlRedirectImportErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectImportPreview",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectImportSubmitPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectImportUserError",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "UrlRedirectInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "UrlRedirectSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UrlRedirectUserError",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "UserError",
          "possibleTypes": null
        },
        {
          "kind": "SCALAR",
          "name": "UtcOffset",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Video",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "VideoSource",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "VoidSession",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "VoidSessionRejectPayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "VoidSessionRejectUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "VoidSessionRejectUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "VoidSessionRejectionReasonInput",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "VoidSessionResolvePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "VoidSessionResolveUserError",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "VoidSessionResolveUserErrorCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "VoidSessionStatus",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "VoidSessionStatusCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "VoidSessionStatusReason",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "VoidSessionStatusReasonRejectionCode",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "WebhookEventBridgeEndpoint",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "WebhookHttpEndpoint",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "WebhookPubSubEndpoint",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "WebhookSubscription",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "WebhookSubscriptionConnection",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "WebhookSubscriptionCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "WebhookSubscriptionDeletePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "WebhookSubscriptionEdge",
          "possibleTypes": null
        },
        {
          "kind": "UNION",
          "name": "WebhookSubscriptionEndpoint",
          "possibleTypes": [
            {
              "name": "WebhookEventBridgeEndpoint"
            },
            {
              "name": "WebhookHttpEndpoint"
            },
            {
              "name": "WebhookPubSubEndpoint"
            }
          ]
        },
        {
          "kind": "ENUM",
          "name": "WebhookSubscriptionFormat",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "WebhookSubscriptionInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "WebhookSubscriptionSortKeys",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "WebhookSubscriptionTopic",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "WebhookSubscriptionUpdatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "Weight",
          "possibleTypes": null
        },
        {
          "kind": "INPUT_OBJECT",
          "name": "WeightInput",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "WeightUnit",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__Directive",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "__DirectiveLocation",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__EnumValue",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__Field",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__InputValue",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__Schema",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "__Type",
          "possibleTypes": null
        },
        {
          "kind": "ENUM",
          "name": "__TypeKind",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "deliveryProfileCreatePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "deliveryProfileRemovePayload",
          "possibleTypes": null
        },
        {
          "kind": "OBJECT",
          "name": "deliveryProfileUpdatePayload",
          "possibleTypes": null
        }
      ]
    }
  },
  "extensions": {
    "cost": {
      "requestedQueryCost": 3,
      "actualQueryCost": 3,
      "throttleStatus": {
        "maximumAvailable": 2000,
        "currentlyAvailable": 1997,
        "restoreRate": 100
      }
    }
  }
}