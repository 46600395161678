import { PMDModel } from './pmdmodel.model';
import { ShippingRate } from './shippingrate.model';

export class ShopifyEdge<T extends PMDModel> implements PMDModel {
    node:T;

    TCreator: { new (json): T; };

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any, TCreator: { new (json): T; }) {
      this.TCreator = TCreator;
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      this.node = json.node ? new this.TCreator(json.node):null;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      var json: any = {
        'node': this.node ? this.node.toJSON():null,
      }
      return json;
    }
}
