import { PMDModel } from './pmdmodel.model';
import { Address } from './address.model';
import { LineItem } from './lineitem.model';
import { MoneyV2 } from './moneyv2.model';

export class Order implements PMDModel {
    // id: string;
    // parent_id: number;
    // number: string;
    // order_key: string;
    // status: string;
    // currency: string;
    // date_created: Date;
    // discount_total: string;
    // discount_tax: string;
    // shipping_total: string;
    // shipping_tax: string;
    // cart_tax: string;
    // total: string;
    // total_tax: string;
    // prices_include_tax: boolean;
    // customer_id: number;
    // customer_note: string;
    // billing: Address;
    // shipping: Address;
    // payment_method: string;
    // payment_method_title: string;
    // transaction_id: string;
    // cart_hash: string;
    // line_items: LineItem[];

    id: string;
    name: string;
    email: string;
    orderNumber: number;
    phone: string;
    customerUrl: string;
    shippingAddress: Address;
    currencyCode: string;
    currentTotalPrice: MoneyV2;
    currentTotalTax: MoneyV2;
    currentSubtotalPrice: MoneyV2;
    originalTotalPrice: MoneyV2;
    subtotalPriceV2: MoneyV2;
    totalPriceV2: MoneyV2;
    totalRefundedV2: MoneyV2;
    totalShippingPriceV2: MoneyV2;
    totalTaxV2: MoneyV2;
    processedAt: Date;
    fulfillmentStatus: string;
    lineItems: any;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      // this.id = json.id;
      // this.parent_id = json.parent_id;
      // this.number = json.number;
      // this.order_key = json.order_key;
      // this.status = json.status;
      // this.currency = json.currency;
      // this.date_created = json.date_created ? new Date(json.date_created):null;
      // this.discount_total = json.discount_total;
      // this.discount_tax = json.discount_tax;
      // this.shipping_total = json.shipping_total;
      // this.shipping_tax = json.shipping_tax;
      // this.cart_tax = json.cart_tax;
      // this.total = json.total;
      // this.total_tax = json.total_tax;
      // this.prices_include_tax = json.prices_include_tax;
      // this.customer_id = json.customer_id;
      // this.customer_note = json.customer_note;
      // this.billing = new Address(json.billing ? json.billing:{});
      // this.shipping= new Address(json.shipping ? json.shipping:{});
      // this.payment_method = json.payment_method;
      // this.payment_method_title = json.payment_method_title;
      // this.transaction_id = json.transaction_id;
      // this.cart_hash = json.cart_hash;
      // this.line_items = [];
      // if (json.line_items) {
      //   for(let item of json.line_items) {
      //     this.line_items.push(new LineItem(item));
      //   }
      // }

      this.id = json.id;
      this.name = json.name;
      this.email = json.email;
      this.orderNumber = json.orderNumber;
      this.phone = json.phone;
      this.customerUrl = json.customerUrl;
      this.shippingAddress = new Address(json.shippingAddress);
      this.currencyCode = json.currencyCode;
      this.currentTotalPrice = json.currentTotalPrice;
      this.currentTotalTax = json.currentTotalTax;
      this.currentSubtotalPrice = json.currentSubtotalPrice;
      this.originalTotalPrice = json.originalTotalPrice;
      this.subtotalPriceV2 = json.subtotalPriceV2;
      this.totalPriceV2 = json.totalPriceV2;
      this.totalRefundedV2 = json.totalRefundedV2;
      this.totalShippingPriceV2 = json.totalShippingPriceV2;
      this.totalTaxV2 = json.totalTaxV2;
      this.processedAt = new Date(json.processedAt);
      this.fulfillmentStatus = json.fulfillmentStatus;
      this.lineItems = json.lineItems;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      // let lineItems = [];
      // for (let item of this.line_items) {
      //   lineItems.push(item.toJSON());
      // }
      // var json: any = {
      //   'id': this.id,
      //   'parent_id': this.parent_id,
      //   'number': this.number,
      //   'order_key': this.order_key,
      //   'status': this.status,
      //   'currency': this.currency,
      //   'date_created': this.date_created ? this.date_created.toISOString():null,
      //   'discount_total': this.discount_total,
      //   'discount_tax': this.discount_tax,
      //   'shipping_total': this.shipping_total,
      //   'shipping_tax': this.shipping_tax,
      //   'cart_tax': this.cart_tax,
      //   'total': this.total,
      //   'total_tax': this.total_tax,
      //   'prices_include_tax': this.prices_include_tax,
      //   'customer_id': this.customer_id,
      //   'customer_note': this.customer_note,
      //   'billing': this.billing?this.billing.toJSON():null,
      //   'shipping': this.shipping?this.shipping.toJSON():null,
      //   'payment_method': this.payment_method,
      //   'payment_method_title': this.payment_method_title,
      //   'transaction_id': this.transaction_id,
      //   'cart_hash': this.cart_hash,
      //   'line_items': lineItems
      // };

      var json: any = {
        'id': this.id,
        'name': this.name,
        'email': this.email,
        'orderNumber': this.orderNumber,
        'phone': this.phone,
        'customerUrl': this.customerUrl,
        'shippingAddress': this.shippingAddress ? this.shippingAddress.toJSON() : null,
        'currencyCode': this.currencyCode,
        'currentTotalPrice': this.currentTotalPrice,
        'currentTotalTax': this.currentTotalTax,
        'currentSubtotalPrice': this.currentSubtotalPrice,
        'originalTotalPrice': this.originalTotalPrice,
        'subtotalPriceV2': this.subtotalPriceV2,
        'totalPriceV2': this.totalPriceV2,
        'totalRefundedV2': this.totalRefundedV2,
        'totalShippingPriceV2': this.totalShippingPriceV2,
        'totalTaxV2': this.totalTaxV2,
        'fulfillmentStatus': this.fulfillmentStatus,
        'processedAt': this.processedAt ? this.processedAt.toISOString() : '',
        'lineItems': this.lineItems,
      }
      return json;
    }

    get formattedCreatedAt():string {
      if (!this.processedAt) return '';
      return this.processedAt.toLocaleDateString('en-US', {day: 'numeric', month: 'long', year: 'numeric',});
    }

    // get totalAsNumber():number {
    //   return Number(this.total);
    // }

    get regularTotal():number {
      // return this.line_items.reduce((total:number, item:LineItem) => total + item.regular_price ? item.regular_price : item.price, 0);
      return 0;
    }
}
