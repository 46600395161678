import { PMDModel } from './pmdmodel.model';
import { MoneyV2 } from './moneyv2.model';

export class ProductPriceRange implements PMDModel {
    maxVariantPrice: MoneyV2;
    minVariantPrice: MoneyV2;
    __typename: "ProductPriceRange";

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      this.maxVariantPrice = new MoneyV2(json.maxVariantPrice);
      this.minVariantPrice = new MoneyV2(json.minVariantPrice);
      this.__typename = json.__typename;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      var json: any = {
        'maxVariantPrice': this.maxVariantPrice.toJSON(),
        'minVariantPrice': this.minVariantPrice.toJSON(),
        '__typename': this.__typename,
      };
      return json;
    }
}
