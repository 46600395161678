import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { DataLayerCustomDimensionsService, PartialCustomDimensionsSet } from './data-layer-custom-dimensions.service';
import { schemaEnvironment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private _dataLayerCustomDimensions: DataLayerCustomDimensionsService,
    private _router: Router,
    private _platform: Platform,
  ) {}

  updateCustomDimensions(someDimensions: PartialCustomDimensionsSet) {
    this._dataLayerCustomDimensions.dimensions = someDimensions;
    this._dataLayerCustomDimensions.trigger();
  }

  pageView(overridePath?: string) {

    this.updateCustomDimensions({
      ecomm_pagetype: "other",
      pagePostType: "page",
      pagePostType2: null
    });
    this.firePageView(overridePath);
  }

  pageViewWithCustomDimensions(someDimensions: PartialCustomDimensionsSet, overridePath?: string) {
    this.updateCustomDimensions({
      ecomm_pagetype: "other",
      pagePostType: "page",
      pagePostType2: null,
      ...someDimensions
    });
    this.firePageView(overridePath);
  }

  decodeHTMLEntities(value:string): string {
    if (!this._platform.isBrowser || !value) { return value; }
    var txt = document.createElement('textarea');
    txt.innerHTML = value;
    return txt.value;
  }

  event(event:string) {
    this._dataLayerCustomDimensions.trigger();
    if (!this._platform.isBrowser) { return; }
    (<any>window).dataLayer.push({
      event: event
    });
  }

  eventEEC(event:string, ecommerce:{[key:string]:any}) {
    if (!this._platform.isBrowser) { return; }
    (<any>window).dataLayer.push({
      event: event,
      ecommerce: ecommerce
    });
  }

  eventWithCustomDimensions(event:string, someDimensions: PartialCustomDimensionsSet) {
    this.updateCustomDimensions(someDimensions);
    this.event(event);
  }

  track404(overridePath?: string) {
    this._dataLayerCustomDimensions.trigger();
    if (!this._platform.isBrowser) { return; }
    let url = schemaEnvironment.url + (overridePath ? overridePath : this._router.url);
    (<any>window).dataLayer.push({
      event: '404',
      virtualPageURL: url,
      virtualPageTitle: url,
    });
  }

  private firePageView(overridePath?: string) {
    if (!this._platform.isBrowser) { return; }
    let url = schemaEnvironment.url + (overridePath ? overridePath : this._router.url);
    (<any>window).dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: url,
      virtualPageTitle: url,
    });
  }

  eventTriggerWithRecord(event:string, someDimensions) {
    const uEmail= someDimensions.email;

    (<any>window)._rsq.push(['_setUserEmail', uEmail]);
    (<any>window)._rsq.push(['_setUserProperties', someDimensions]);
    (<any>window)._rsq.push(['_setAction', 'email_entered']);
    (<any>window)._rsq.push(['_track']);
  }
  
  

}
