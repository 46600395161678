import { PMDModel } from './pmdmodel.model';

export class CustomerUserError implements PMDModel {
    code: string;
    field: string[];
    message: string;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      this.code = json.code;
      this.field =  json.field;
      this.message = json.message;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      var json: any = {
        'code': this.code,
        'field': this.field,
        'message': this.message,
      };
      return json;
    }
}
