<div class="col-md-12 text-center">
    <img style="width:7rem;" src='../../assets/logo.png'>
  </div>
  <div class="col-md-12 logout_btn">
    <img style="width:2rem;" src='../../assets/logout.svg' (click)="logout()">
  </div>
  <div class="col-md-12 text-center header">
    Sample Product Order
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-sd-6">
        <div>
          <form #orderForm="ngForm" (ngSubmit)="submit()">
            <!-- <div class="form-group mt-3">
              <label>Recipient Name</label>
              <input type="text" name="recipient_name" class="form-control input" id="id" [(ngModel)]='recipient_name'
                placeholder="Enter the Recipient Name">
            </div>
            <div class="form-group mt-3">
              <label>Recipient Email</label>
              <input type="text" name="recipient_email" class="form-control input" id="name" [(ngModel)]='recipient_email'
                placeholder="Enter the Recipient Email">
            </div> -->
            <div class="form-group mt-3">
              <label>Shipping details</label>
              <input type="text" name="c_p_name" class="form-control input" id="name" [(ngModel)]='person_name' required
                placeholder="First Name*">
                <div class="form-group mt-3">  <input type="text" name="c_l_name"  class="form-control input" id="l_name" [(ngModel)]='last_name' required
                placeholder="Last Name*"></div>
              <div class="form-group mt-3"><input type="text" name="c_number" class="form-control input" id="name" required
                  [(ngModel)]='person_number' placeholder="Contact Number*"></div>
              <div class="form-group mt-3"><input type="text" name="shipping_info" class="form-control input" required
                  id="shipping_info" [(ngModel)]='shipping_info' placeholder="Shipping Address*"></div>
              <div class="form-group mt-3"> <input type="text" name="city" class="form-control input" id="city" required
                  [(ngModel)]='city' placeholder="City*"></div>
              <div class="form-group mt-3"> <input type="text" name="state" class="form-control input" id="state"
                  [(ngModel)]='state' placeholder="State"></div>
              <div class="form-group mt-3"> <input type="text" name="p_code" class="form-control input" id="p_code" required
                  [(ngModel)]='pincode' placeholder="Postal Code*"></div>
              <div class="form-group mt-3"><input type="text" name="country" class="form-control input" id="country" required
                  [(ngModel)]='country' placeholder="Country*"></div>
              <div class="form-group mt-3"> <input type="email" name="email" class="form-control input" id="email" required
                  [(ngModel)]='email' placeholder="Email For Tracking*"></div>
            </div>
            <form #search="ngForm" (ngSubmit)="getProducts()">
              <div class="form-group mt-3">
                <label>Product Details</label>
                <input class="form-control" autocomplete="off" required type="text" list="num" #searchBox
                  [(ngModel)]="searchString" aria-label="Search for products" autofocus
                  [ngModelOptions]="{standalone: true}" (change)="get_product_list($event)"
                  placeholder="Select Product*" />
                 <datalist id="num">
                  <option selected value=''>Select Sku</option>
                  <option placeholder="Select Product*" name="p_list.name" #selectem *ngFor="let p_list of p_details"
                    [ngValue]="p_list">{{p_list.name}}</option>
                </datalist>
              </div>
              <div class="overlay-loader" *ngIf='loading'>
                <app-loading></app-loading>
              </div>
            </form>
            <div class="cart form-group mt-3" *ngFor="let data of p_sku._sku; let i = index;">
            
              <div class=" col-4 img_cen">
                <img style="width: -webkit-fill-available;" [src]='data.img'>
              </div>
              <div class=" col-7">
                <h3 class='title'>{{data.name}}</h3>
                <p class='des'>{{data.des}}</p>
                <!-- <div class='price'><label>{{data.amt}}</label> </div> -->
                <div class="in_btn">
                  <button type="button" class="quantity-button reduce-quantity" aria-label="Reduce quantity by 1"
                    (click)="changeQuantity(i, -1)">
                    -
                  </button>
                  <input class="quantity-amount" name={{i}} aria-label="Item quantity" #quantity [ngModel]="data.value" />
                  <button type="button" aria-label="Increase quantity by 1" class="quantity-button increase-quantity"
                    (click)="changeQuantity(i, +1)">
                    +
                  </button>
                </div>
                <div>
                  <div class="form-group mt-3" *ngIf='data.variant_section.length > 1'>
                   <select class="form-control input"  (change)="changeVariant(i,$event)" [ngModelOptions]="{standalone: true}">
                      <option selected value={{data.selected_variant_id}}>{{data.selected_variant_id}}</option>
                      <option placeholder="Select Variant Color*" name="v_section" *ngFor="let v_section of data.variant_section"
                      value={{v_section.variant_id}}>{{v_section.color}}</option>
                  </select>
                </div>
                </div>
              </div>
                <div class="col-2" (click)='remove_product_sku(i)'>
                <div class='cls_img'><img style="width: 1rem;" src='../../assets/close.svg'></div>
              </div>
              
              
            </div>
            <div class="form-group mt-3">
              <label>Select Warehouse </label>
              <select class="form-control input" [(ngModel)]='warehouse' [ngModelOptions]="{standalone: true}">
                <option selected value=''>Select Warehouse*</option>
                <option placeholder="Select Warehouse*" name="w_section" *ngFor="let w_section of warehouse_section"
                  value={{w_section.value}}>{{w_section.name}}</option>
              </select>
            </div>
            <div class="form-group mt-3">
              <label>Reason</label>
              <select class="form-control input" [(ngModel)]='reason' [ngModelOptions]="{standalone: true}">
                <option selected value=''>Select Reason*</option>
                <option placeholder="Select Reason*" name="r_section" *ngFor="let r_section of reason_section"
                  value={{r_section.value}}>{{r_section.name}}</option>
              </select>
            </div>
            <div class="col-md-12 text-center mt-3 col-sd-12" >
              <div class="col-md-12 text-center mt-3 col-sd-12" >
              <span class="only-show-on-hover" *ngIf='!orderForm.valid'>All the Fields are Mandatory*</span>
            </div>
              <button class="btn bt" [disabled]="!orderForm.valid"  >Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
        