import { PMDModel } from './pmdmodel.model';

export class ProductImage implements PMDModel {
    // id: number;
    // src: string;
    // name: string;
    // alt: string;
    // thumbnail: string;
    // srcset: string;

    id: string;
    originalSrc: string;
    transformedSrc: string;
    sku: string;
    alt: string;
    hoverImage: boolean;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json: any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }

      this.hoverImage = false;

      (json.altText || '')
        .split('|')
        .filter(Boolean)
        .forEach((altEntry) => {
          const trimmedEntry = altEntry.trim()

          if (!this.hoverImage && trimmedEntry === 'hover-image') {
            this.hoverImage = true
          } else if (!this.sku && /^[a-zA-Z0-9-_]+$/.test(trimmedEntry)) {
            this.sku = trimmedEntry            
          } else if (!this.alt && trimmedEntry.length) {
            this.alt = trimmedEntry
          }
        })

      this.id = json.id;
      this.originalSrc = json.originalSrc;
      this.transformedSrc = json.transformedSrc;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      // var json: any = {
      //   'id': this.id,
      //   'src': this.src,
      //   'name': this.name,
      //   'alt': this.alt,
      //   'thumbnail': this.thumbnail,
      //   'srcset': this.srcset
      // };
      var json: any = {
        'id': this.id,
        'altText': [this.sku, this.hoverImage && 'hover-image', this.alt].filter(Boolean).join('|'),
        'originalSrc': this.originalSrc,
        'transformedSrc': this.transformedSrc,
      };
      return json;
    }
}
