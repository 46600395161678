import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import { CookieService } from './cookie.service';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

import { Observable, ReplaySubject, of, from } from 'rxjs';
import { map, tap, flatMap } from 'rxjs/operators';
import { Address } from '../shared/models/address.model';
import { User } from '../shared/models/user.model';
import { Order } from '../shared/models/order.model';
import { Subscription } from '../shared/models/subscription.model';

import { AnalyticsService } from './gtm/analytics.service';

import { Buffer } from 'buffer';
import { CustomerUserError } from '../shared/models/customerusererror.model';

const APINAMESPACE = '/wp-json/pmd/v1/myaccount';
const subscriptionPath = '/subscriptions';
let token: String;

const customerAccessTokenCreate = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerUserErrors {
        code
        field
        message
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`;

const customerCreate = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const customerUpdate = gql`
  mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
      customer {
        id
        email
        phone
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const customerAddressCreate = gql`
  mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
      customerUserErrors {
        code
        field
        message
      }
      customerAddress {
        id
      }
    }
  }
`;

const customerAddressUpdate = gql`
  mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
    customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const customerAccessTokenDelete = gql`
  mutation customerAccessTokenDelete($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        field
        message
      }
    }
  }
`;

interface SubUpdateParams {
  next_payment_date?:string;
  billing_period?:string;
  billing_interval?:string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  get isLoggedIn(): boolean {
    return !!this._currentUser;
  }
  public currentUserStream: Observable<User>;
  private _currentUserSubject: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _currentUser: User;
  private _refreshCurrentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private _transferState: TransferState,
    private _platform: Platform,
    private _cookieService: CookieService,
    private _analyticsService: AnalyticsService,
    private apollo: Apollo,
  ) {
    this.currentUserStream = this._currentUserSubject.asObservable();
    // Try refreshing the current user, or fail if we're not logged in
    this.refreshCurrentUser().subscribe(
      _ => { /* We need to subscribe to this event, to cause it to happen */ },
      err => {
        // User isn't logged in
      }
    );
    this.currentUserStream.subscribe(u => {
      if (!u) {
        this._analyticsService.updateCustomDimensions({visitorId: undefined });
      } else {
        // Todo
        this._analyticsService.updateCustomDimensions({
          visitorId: `${u.getDecodedId()}`,
          visitorEmail: u.email,
          ...u.defaultAddress.getDataLayerBilling(),
          ...u.defaultAddress.getDataLayerShipping()
        });
      }
    });
  }

  getCurrentUser(): Observable<User> {
    if (this._currentUser) {
      return of(this._currentUser);
    }
    return this.refreshCurrentUser();
  }

  refreshCurrentUser(): Observable<User> {
    // Check if user is cached from server
    const USER_KEY = makeStateKey<Object>('current-user');
    if (this._transferState.hasKey(USER_KEY)) {
      const user = this._transferState.get<Object>(USER_KEY, null);
      this._transferState.remove(USER_KEY);
      if (user) {
        this._currentUser = new User(user);
        this._currentUserSubject.next(this._currentUser);
        return of(this._currentUser);
      }
    }
    if (this._refreshCurrentUser) {
      return this._refreshCurrentUser;
    }
    
    token = this._cookieService.get('user-token');
    
    const getUser = gql`
      query {
        customer(customerAccessToken: "${token}") {
          id,
          firstName,
          lastName,
          displayName,
          email,
          phone,
          defaultAddress {
            id
            firstName
            lastName
            address1
            address2
            city
            company
            country
            phone
            province
            zip
          }
        }
      }
    `;
    this._refreshCurrentUser = this.apollo.watchQuery<any>({
      query: getUser,
    })
    .valueChanges.pipe(
      map(({ data }) => data.customer),
      tap(customer => {
        if (!this._platform.isBrowser) {
          this._transferState.set(USER_KEY, customer);
        }
        this._refreshCurrentUser = null;
      }),
      map(customer => {
        if (customer === null) {
          this._refreshCurrentUser = null;
          this._currentUser = null;
          this._currentUserSubject.next(null);
        } else {
          this._currentUser = new User(customer);
  
          this._currentUserSubject.next(this._currentUser);
        }
        return this._currentUser;
      })
    );
    return this._refreshCurrentUser;
  }

  login(log:string, pwd:string, remember:boolean=false):Observable<User|CustomerUserError[]> {
    let p = {"input": { email: log, password: pwd }};
    // let headers = new HttpHeaders();
    // Todo: already implemented graphql, but have to fix some issues
    this._cookieService.delete('csrftoken');
    this._cookieService.delete('user-token');
    return this.apollo.mutate<any>({
      mutation: customerAccessTokenCreate,
      variables: p,
    })
    .pipe(
      flatMap((res:any) => {
        if (!res || !res.data || !res.data.customerAccessTokenCreate || res.data.customerAccessTokenCreate.customerUserErrors.length > 0) {
          if (res.data.customerAccessTokenCreate.customerUserErrors.length > 0) {
            return of(res.data.customerAccessTokenCreate.customerUserErrors.map(e=> new CustomerUserError(e)));
          }
          return of(null);
        }
        this._cookieService.set('user-token',  res.data.customerAccessTokenCreate.customerAccessToken.accessToken);
        return this.refreshCurrentUser();
      })
    )
  }

  getMultiPassToken(email:string, return_to:string):Observable<{token:string}> {
    let accessToken = this._cookieService.get('user-token');
    let headers = new HttpHeaders({'access-token': accessToken});
    let body = { email: email, return_to:return_to};

    return this.http.post<{token:string}>(
      `${environment.serviceAPIURL}/multipass`,
      body,
      {
        headers:headers
      }
    )
  }

  logout():Observable<void> {
    const tokenVariable = { "customerAccessToken": this._cookieService.get('user-token') };
    return this.apollo.mutate<any>({
      mutation: customerAccessTokenDelete,
      variables: tokenVariable,
    }).pipe(
      map(({ data }) => data),
      map(logoutData => {
        this._cookieService.delete('user-token');
        this._currentUser = null;
        this._currentUserSubject.next(this._currentUser);
      })
    )
  }

  register(log:string, pwd:string, mrkt:boolean = false):Observable<User|CustomerUserError[]> {
    let p = {"input": {email: log, password: pwd, acceptsMarketing:mrkt}};
    // let headers = new HttpHeaders();
    return this.apollo.mutate<any>({
      mutation: customerCreate,
      variables: p,
    })
    .pipe(
      flatMap((res:any) => {
        if (!res || !res.data || !res.data.customerCreate || res.data.customerCreate.customerUserErrors.length > 0)  {
          if (res.data.customerCreate.customerUserErrors.length > 0) {
            return of(res.data.customerCreate.customerUserErrors.map(e=> new CustomerUserError(e)));
          }
          return of(null);
        }
        return this.login(log, pwd);
      })
    );
  }

  changePassword(password:string) {

    let p = {"customerAccessToken": this._cookieService.get('user-token'), "customer": {password: password}};
    return this.apollo.mutate<any>({
      mutation: customerUpdate,
      variables: p,
    })
    .pipe(
      map(({ data }) => data.customerUpdate),
      map(updatedData => {
        if (updatedData.customerUserErrors.length > 0) return;
        this._cookieService.set('user-token',  updatedData.customerAccessToken.accessToken);
        this._currentUser.id = updatedData.customer.id;
        this._currentUserSubject.next(this._currentUser);
        return updatedData;
      })
    );
  }

  updatePhone(phone:string) {
    // let headers = new HttpHeaders();
    // return this.http.put(
    //   environment.apiURL + APINAMESPACE + '/phone',
    //   {phone: phone},
    //   {
    //     headers: headers
    //   }
    // ).pipe(map(res=>{
    //   // Todo
    //   // this._currentUser.billing_address.phone = phone;
    //   // this._currentUser.shipping_address.phone = phone;
    //   this._currentUserSubject.next(this._currentUser);
    //   return res;
    // }));
    let p = {"customerAccessToken": this._cookieService.get('user-token'), "customer": {phone: phone}};
    return this.apollo.mutate<any>({
      mutation: customerUpdate,
      variables: p,
    })
    .pipe(
      map(({ data }) => data.customerUpdate),
      map(updatedData => {
        if (updatedData.customerUserErrors.length > 0) return;
        this._currentUser.id = updatedData.customer.id;
        this._currentUser.phone = updatedData.customer.phone;
        this._currentUserSubject.next(this._currentUser);
        return updatedData;
      })
    );
  }

  updateEmail(email:string) {
    // let headers = new HttpHeaders();
    // return this.http.put(
    //   environment.apiURL + APINAMESPACE + '/email',
    //   {email: email},
    //   {
    //     headers: headers
    //   }
    // ).pipe(map(res=>{
    //   this._currentUser.email = email;
    //   this._currentUserSubject.next(this._currentUser);
    //   return res;
    // }));
    let p = {"customerAccessToken": this._cookieService.get('user-token'), "customer": {email: email}};
    return this.apollo.mutate<any>({
      mutation: customerUpdate,
      variables: p,
    })
    .pipe(
      map(({ data }) => data.customerUpdate),
      map(updatedData => {
        if (updatedData.customerUserErrors.length > 0) return;
        this._currentUser.id = updatedData.customer.id;
        this._currentUser.email = updatedData.customer.email;
        this._currentUserSubject.next(this._currentUser);
        return updatedData;
      })
    );
  }

  updateAddress(address:Address, type:'billing'|'shipping'='billing') {
    // let headers = new HttpHeaders();
    // let params = address.toJSON(true);
    // params.type = type;
    // return this.http.put(
    //   environment.apiURL + APINAMESPACE + '/address',
    //   params,
    //   {
    //     headers: headers
    //   }
    // ).pipe(map(res=>{
    //   // Todo
    //   // if (type == 'billing') {
    //   //   this._currentUser.billing_address = address;
    //   // } else {
    //   //   this._currentUser.shipping_address = address;
    //   // }
    //   this._currentUserSubject.next(this._currentUser);
    //   return res;
    // }));

    let p = {
      "customerAccessToken": this._cookieService.get('user-token'),
      "address": {
        "firstName": address.firstName,
        "lastName": address.lastName,
        "address1": address.address1,
        "address2": address.address2,
        "company": address.company,
        "city" : address.city,
        "country": address.country,
        "province": address.province,
        "zip": address.zip,
      }
    };
    if (this._currentUser.defaultAddress.id) {
      p['id'] = this._currentUser.defaultAddress.id;
      return this.apollo.mutate<any>({
        mutation: customerAddressUpdate,
        variables: p,
      })
      .pipe(
        map(({ data }) => data.customerAddressUpdate),
        map(updatedData => {
          if (updatedData.customerUserErrors.length > 0) return;
          this._currentUser.defaultAddress.firstName = p.address.firstName;
          this._currentUser.defaultAddress.lastName = p.address.lastName;
          this._currentUser.defaultAddress.address1 = p.address.address1;
          this._currentUser.defaultAddress.address2 = p.address.address2;
          this._currentUser.defaultAddress.company = p.address.company;
          this._currentUser.defaultAddress.city = p.address.city;
          this._currentUser.defaultAddress.country = p.address.country;
          this._currentUser.defaultAddress.province = p.address.province;
          this._currentUser.defaultAddress.zip = p.address.zip;
          this._currentUserSubject.next(this._currentUser);
          return updatedData;
        })
      );
    }
    return this.apollo.mutate<any>({
      mutation: customerAddressCreate,
      variables: p,
    })
    .pipe(
      map(({ data }) => data.customerAddressCreate),
      map(updatedData => {
        if (updatedData.customerUserErrors.length > 0) return;
        this._currentUser.defaultAddress.id = updatedData.customerAddress.id;
        this._currentUser.defaultAddress.firstName = p.address.firstName;
        this._currentUser.defaultAddress.lastName = p.address.lastName;
        this._currentUser.defaultAddress.address1 = p.address.address1;
        this._currentUser.defaultAddress.address2 = p.address.address2;
        this._currentUser.defaultAddress.company = p.address.company;
        this._currentUser.defaultAddress.city = p.address.city;
        this._currentUser.defaultAddress.country = p.address.country;
        this._currentUser.defaultAddress.province = p.address.province;
        this._currentUser.defaultAddress.zip = p.address.zip;
        this._currentUserSubject.next(this._currentUser);
        return updatedData;
      })
    );
  }

  syncUserOrders():Observable<number> {
    const SYNC_KEY = makeStateKey<any[]>('sync-orders');
    if (this._transferState.hasKey(SYNC_KEY)) {
      const changed = this._transferState.get<number>(SYNC_KEY, null);
      this._transferState.remove(SYNC_KEY);
      return of(changed);
    }

    let headers = new HttpHeaders();
    return this.http.get<number>(
      environment.apiURL + APINAMESPACE + '/sync-orders',
      {
        headers: headers
      }
    ).pipe(
      tap(res=> {
        // If we're on the server cache the orders
        if (!this._platform.isBrowser) {
          this._transferState.set(SYNC_KEY, res);
        }
      })
    )
  }

  getOrders():Observable<Order[]> {
    // const ORDERS_KEY = makeStateKey<any[]>('user-orders');
    // if (this._transferState.hasKey(ORDERS_KEY)) {
    //   const orders = this._transferState.get<any[]>(ORDERS_KEY, null);
    //   this._transferState.remove(ORDERS_KEY);
    //   return of(orders.map(o=>new Order(o)));
    // }

    token = this._cookieService.get('user-token');

    const orders = gql`
      query {
        customer(customerAccessToken: "${token}") {
          id,
          firstName,
          lastName,
          orders(first: 10) {
            edges {
              node {
                id
                name
                orderNumber
                fulfillmentStatus
                shippingAddress {
                  id
                  address1
                  address2
                  city
                  company
                  country
                  firstName
                  lastName
                  phone
                  province
                  provinceCode
                  zip
                }
                totalPriceV2 {
                  amount
                }
                lineItems(first: 10) {
                  edges {
                    node {
                      title
                      quantity
                      variant {
                        image {
                          originalSrc
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    return this.apollo.watchQuery<any>({
      query: orders,
    })
    .valueChanges.pipe(
      map(({ data }) => data.customer.orders.edges),
      map(orderData => {
        return orderData.map(order => order.node);
      })
    );

    // let headers = new HttpHeaders();
    // let p = new HttpParams({ fromObject: {'customer': this._currentUser.id.toString() } });
    // return this.http.get<any[]>(
    //   environment.apiURL + '/wp-json/wc/v3/pmd/orders',
    //   {
    //     headers: headers,
    //     params: p
    //   }
    // ).pipe(
    //   tap(res=>{
    //     // If we're on the server cache the orders
    //     if (!this._platform.isBrowser) {
    //       this._transferState.set(ORDERS_KEY, res);
    //     }
    //   }),
    //   map(res=>res.map(o=>new Order(o)))
    // );
  }

  getSubscriptions():Observable<Subscription[]> {
    // const SUBSCRIPTIONS_KEY = makeStateKey<any[]>('user-subscriptions');
    // if (this._transferState.hasKey(SUBSCRIPTIONS_KEY)) {
    //   const subs = this._transferState.get<any[]>(SUBSCRIPTIONS_KEY, null);
    //   this._transferState.remove(SUBSCRIPTIONS_KEY);
    //   return of(subs.map(s=>new Subscription(s)));
    // }

    // let headers = new HttpHeaders();
    // let p = new HttpParams({fromObject: {'customer': this._currentUser.id.toString()}});
    // return this.http.get<any[]>(
    //   environment.apiURL + '/wp-json/wc/v3/pmd/subscriptions',
    //   {
    //     headers: headers,
    //     params: p
    //   }
    // ).pipe(
    //   tap(res=>{
    //     // If we're on the server cache the orders
    //     if (!this._platform.isBrowser) {
    //       this._transferState.set(SUBSCRIPTIONS_KEY, res);
    //     }
    //   }),
    //   map(res=>res.map(s=>new Subscription(s)))
    // );

    let headers = new HttpHeaders({
      // 'X-Recharge-Access-Token': environment.rechargeAccessToken,
      // 'Accept': 'application/json',
      // 'Access-Control-Allow-Origin': 'http://localhost:4200',
      // "Content-Type": "application/json",
      // 'Access-Control-Allow-Credentials': 'true'
    });

    let customer_id = Buffer.from(this._currentUser.id, 'base64').toString();
    customer_id = customer_id.split('/').pop();
    if (!customer_id) {
      return;
    }

    return this.http.get<any[]>(
      environment.rechargeAPIUrl + '/subscriptions',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        console.log('subscription result', res);
      })
    );
  }

  getSubscriptionHash(customerId: string): Observable<{hash:string}> {
    let headers = new HttpHeaders();
    let p = new HttpParams({ fromObject: {shopify_customer_id: customerId} });
    //TODO: Bill finish the link stuff here
    return this.http.get<any>(
      `${environment.serviceAPIURL}${subscriptionPath}/customers`,
      {
        headers: headers,
        params: p,
      }
    );
  }

  getSubscription(id:string):Observable<Subscription> {
    const SUBSCRIPTION_KEY = makeStateKey<any[]>('user-subscription-' + id);
    if (this._transferState.hasKey(SUBSCRIPTION_KEY)) {
      const sub = this._transferState.get<any>(SUBSCRIPTION_KEY, null);
      this._transferState.remove(SUBSCRIPTION_KEY);
      return of(new Subscription(sub));
    }

    let headers = new HttpHeaders();
    let p = new HttpParams({fromObject: {'customer': this._currentUser.id.toString()}});
    return this.http.get<any>(
      environment.apiURL + '/wp-json/wc/v3/pmd/subscriptions/' + id,
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res=>{
        // If we're on the server cache the orders
        if (!this._platform.isBrowser) {
          this._transferState.set(SUBSCRIPTION_KEY, res);
        }
      }),
      map(res=>new Subscription(res))
    );
  }

  updateSubscription(id:string|number, params:SubUpdateParams) {
    let headers = new HttpHeaders();
    return this.http.put<any>(
      environment.apiURL + '/wp-json/wc/v3/pmd/subscriptions/'+ id,
      params,
      {
        headers: headers,
        params: new HttpParams({fromObject: {'customer': this._currentUser.id.toString()}})
      }
    ).pipe(map(res=>new Subscription(res)));
  }

  cancelSubscription(id:string|number) {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      environment.apiURL + '/wp-json/wc/v3/pmd/subscriptions/'+ id,
      {
        headers: headers,
        params: new HttpParams({fromObject: {'customer': this._currentUser.id.toString()}})
      }
    ).pipe(map(res=>new Subscription(res)));
  }

  reActivateSubscription(id:string|number) {
    let headers = new HttpHeaders();
    return this.http.post<any>(
      environment.apiURL + '/wp-json/wc/v3/pmd/subscriptions/'+ id,
      {},
      {
        headers: headers,
        params: new HttpParams({fromObject: {'customer': this._currentUser.id.toString()}})
      }
    ).pipe(map(res=>new Subscription(res)));
  }

  getSubscriptionOrders(id:string):Observable<Order[]> {
    const SUBSCRIPTION_ORDERS_KEY = makeStateKey<any[]>('user-subscription-orders');
    if (this._transferState.hasKey(SUBSCRIPTION_ORDERS_KEY)) {
      const orders = this._transferState.get<any[]>(SUBSCRIPTION_ORDERS_KEY, null);
      this._transferState.remove(SUBSCRIPTION_ORDERS_KEY);
      return of(orders.map(o=>new Order(o)));
    }

    let headers = new HttpHeaders();
    let p = new HttpParams({fromObject: {'customer': this._currentUser.id.toString()}});
    return this.http.get<any[]>(
      environment.apiURL + '/wp-json/wc/v3/pmd/subscriptions/'+ id + '/orders',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res=>{
        // If we're on the server cache the orders
        if (!this._platform.isBrowser) {
          this._transferState.set(SUBSCRIPTION_ORDERS_KEY, res);
        }
      }),
      map(res=>res.map(o=>new Order(o)))
    );
  }
}
