import { PMDModel } from './pmdmodel.model';
import { environment } from '../../../environments/environment';

export class Address implements PMDModel {
    // first_name: string;
    // last_name: string;
    // company: string;
    // address: string;
    // apartment: string;
    // city: string;
    // country: string = environment.currentSite == 'au' ? 'AU' : environment.currentSite == 'eu' ? 'DE' : environment.currentSite == 'uk' ? 'GB' : 'US';
    // state: string = null;
    // postcode: string;
    // phone: string;

    id: string;
    address1: string;
    address2: string;
    city: string;
    company: string;
    country: string;
    countryCodeV2: string = environment.currentSite == 'au' ? 'AU' : environment.currentSite == 'eu' ? 'DE' : environment.currentSite == 'uk' ? 'GB' : 'US';
    firstName: string;
    lastName: string;
    phone: string;
    province: string = null;
    provinceCode: string;
    zip: string;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      // this.first_name = json.first_name;
      // this.last_name = json.last_name;
      // this.company = json.company;
      // this.address = json.address ? json.address:json.address_1;
      // this.apartment = json.apartment ? json.apartment:json.address_2;
      // this.city = json.city;
      // this.country = json.country;
      // if (!this.country) this.country = environment.currentSite == 'eu' ? 'DE' : 'US';
      // this.state = json.state;
      // this.postcode = json.postcode;
      // this.phone = json.phone;

      this.id = json.id ? json.id : '';
      this.firstName = json.firstName ? json.firstName : '';
      this.lastName = json.lastName ? json.lastName : '';
      this.company = json.company ? json.company : '';
      this.address1 = json.address1 ? json.address1 : '';
      this.address2 = json.address2 ? json.address2 : '';
      this.city = json.city ? json.city : '';
      this.country = json.country ? json.country : '';
      if (!this.countryCodeV2) this.countryCodeV2 = environment.currentSite == 'eu' ? 'DE' : 'US';
      this.province = json.province ? json.province : '';
      this.provinceCode = json.provinceCode ? json.provinceCode : '';
      this.zip = json.zip ? json.zip : '';
      this.phone = json.phone ? json.phone : '';
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON(forAPI=false) {
      // var json: any = {
      //   first_name: this.first_name,
      //   last_name: this.last_name,
      //   company: this.company,
      //   city: this.city,
      //   country: this.country,
      //   state: this.state,
      //   postcode: this.postcode,
      //   phone: this.phone,
      // };
      // if (forAPI) {
      //   json['address_1'] = this.address;
      //   json['address_2'] = this.apartment;
      //   return json;
      // }
      // json.address = this.address;
      // json.apartment = this.apartment;
      // return json;

      var json: any = {
        firstName: this.firstName,
        lastName: this.lastName,
        company: this.company,
        city: this.city,
        country: this.country,
        province: this.province,
        zip: this.zip,
        phone: this.phone,
      };
      if (forAPI) {
        json['address1'] = this.address1;
        json['address2'] = this.address2;
        return json;
      }
      json.address1 = this.address1;
      json.address2 = this.address2;
      return json;
    }

    get formatted():string {
      // let address = '<p style="margin:0">';
      // address +=  this.first_name + ' ' + this.last_name;
      // address += '<br/>' + this.address + (this.apartment? ', ' + this.apartment: '');
      // address += '<br/>' + this.city + ', ' + this.state + ' ' + this.postcode;
      // address += '</p>';
      // return address;

      let address = '<p style="margin:0">';
      address +=  this.firstName + ' ' + this.lastName;
      address += '<br/>' + this.address1 + (this.address2? ', ' + this.address2: '');
      address += '<br/>' + this.city + ', ' + this.province + ' ' + this.zip;
      address += '</p>';
      return address;
    }

    getDataLayerBilling(email:string="") {
      // let dimensions:any = {};
      // dimensions.customerBillingAddress1 = this.address;
      // dimensions.customerBillingAddress2 = this.apartment;
      // dimensions.customerBillingCity = this.city;
      // dimensions.customerBillingCompany = this.company;
      // dimensions.customerBillingCountry = this.country;
      // dimensions.customerBillingEmail = email;
      // dimensions.customerBillingFirstName = this.first_name;
      // dimensions.customerBillingLastName = this.last_name;
      // dimensions.customerBillingPhone = this.phone;
      // dimensions.customerBillingPostcode = this.postcode;
      // dimensions.customerFirstName = this.first_name;
      // dimensions.customerLastName = this.last_name;
      // return dimensions;

      let dimensions:any = {};
      dimensions.customerBillingAddress1 = this.address1;
      dimensions.customerBillingAddress2 = this.address2;
      dimensions.customerBillingCity = this.city;
      dimensions.customerBillingCompany = this.company;
      dimensions.customerBillingCountry = this.country;
      dimensions.customerBillingEmail = email;
      dimensions.customerBillingFirstName = this.firstName;
      dimensions.customerBillingLastName = this.lastName;
      dimensions.customerBillingPhone = this.phone;
      dimensions.customerBillingPostcode = this.zip;
      dimensions.customerFirstName = this.firstName;
      dimensions.customerLastName = this.lastName;
      return dimensions;
    }

    getDataLayerShipping() {
      // let dimensions:any = {};
      // dimensions.customerShippingAddress1 = this.address;
      // dimensions.customerShippingAddress2 = this.apartment;
      // dimensions.customerShippingCity = this.city;
      // dimensions.customerShippingCompany = this.company;
      // dimensions.customerShippingCountry = this.country;
      // dimensions.customerShippingFirstName = this.first_name;
      // dimensions.customerShippingLastName = this.last_name;
      // dimensions.customerShippingPostcode = this.postcode;
      // return dimensions;

      let dimensions:any = {};
      dimensions.customerShippingAddress1 = this.address1;
      dimensions.customerShippingAddress2 = this.address2;
      dimensions.customerShippingCity = this.city;
      dimensions.customerShippingCompany = this.company;
      dimensions.customerShippingCountry = this.country;
      dimensions.customerShippingFirstName = this.firstName;
      dimensions.customerShippingLastName = this.lastName;
      dimensions.customerShippingPostcode = this.zip;
      return dimensions;
    }
}
