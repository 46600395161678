import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from '../services/cookie.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginauthGuard implements CanActivate {
  
  constructor(private _cookieService: CookieService, public router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const tokenVariable = { "customerAccessToken": this._cookieService.get('user-token') };
      if(tokenVariable.customerAccessToken != ''){
        return true;
      }else{
        this.router.navigate(['/'], {queryParamsHandling: 'merge'});
        return false;
       
      }
}
  
}
