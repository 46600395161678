

<div class="col-md-12 text-center  ">
  <img style="width:7rem;" src='../../assets/logo.png'>
 </div>  
<div class="container" style="margin-top: 40px;">

  <div class="row">
    <div class="col-sm-4 offset-sm-4">

      <div> 
        <form>
          <div class="col-md-12 text-center">
            <label class='log'>Login</label>  
            </div>
          <div class="form-group mt-3">         
            <input  type="text" name="id" class="form-control input" id="id" [(ngModel)]='username'  placeholder="Username"> 
            </div>
            <div class="form-group mt-3">           
            <input  type="password" name="name" class="form-control input" id="name" [(ngModel)]='password' placeholder="Password">
            </div>           
           </form>  
           <div class="col-md-12 text-center mt-3 col-sd-12">
            <p class="error" *ngIf="loginError">{{loginError}}</p>
            <button class="btn bt" (click)="login()">Submit</button>    
           </div>  
        </div>    
      </div>  
    </div>
  </div>

  <div class="overlay-loader" *ngIf='loading' >
    <app-loading></app-loading>
  </div>