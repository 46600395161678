import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { AccountService } from '../services/account.service';
import { AjaxService } from '../services/ajax.service';
import { NgForm } from '@angular/forms';
import { ProductRequestParams } from '../shared/models/productrequestparams.model';
import { ProductService } from '../services/product.service';
import { Router } from '@angular/router';
import { User } from '../shared/models/user.model';
import { environment } from '../../environments/environment';

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
  // recipient_name:string = "";
  // recipient_email:string = "";
  user: User;
  warehouse: string = "";
  reason: string = "";
  person_name: string = "";
  last_name: string = "";
  person_number: string = "";
  land_mark: string = "";
  country: string = "";
  state: string = "";
  city: string = "";
  shipping_info: string = "";
  pincode: string = "";
  email: string = "";
  searchString: string = "";
  selected_product: string = "";
  selected_variant: string = "";
  loading: boolean = false;
  warehouse_section = [
    { name: "Australia", value: "au" },
    { name: "Europe", value: "eu" },
    { name: "United Kingdom", value: "uk" },
    { name: "United States", value: "us" },
    { name: "Canada", value: "ca" },
  ];
  // variant_section = ['Blush','Berry','Black','Pink','Taupe','Teal','White','Lavender','Concrete','Mermaid']
  reason_section = [
    { name: "Buyer Samples", value: "buyer_samples" },
    { name: "International Buyer Samples", value: "int_buyer_samples" },
    { name: "International Influencer", value: "int_influencer" },
    { name: "Influencer", value: "influencer" },
    { name: "Gratis", value: "gratis" },
    { name: "Reviews", value: "reviews" },
    { name: "Marketing Samples", value: "marketing_samples" },
    { name: "Content Samples", value: "content_samples" },
    { name: "PR Samples", value: "pr_samples" },
  ];
  variant_section = [];
  p_details = [];
  p_sku = {
    _sku: [],
  };

  @ViewChild("orderForm") orderForm: NgForm;
  @ViewChild("searchBox") searchBox: ElementRef;

  constructor(
    public router: Router,
    private _productService: ProductService,
    private _accountService: AccountService,
    private _ajaxService: AjaxService
  ) {}

  get_product_list(ev) {
    for (var i = 0; i < this.p_details.length; i++) {
      if (this.p_details[i].name == ev.target.value)
        this.p_sku._sku.push({
          name: ev.target.value,
          img: this.p_details[i].img,
          des: this.p_details[i].des,
          value: 1,
          amt: this.p_details[i].amt,
          id: this.p_details[i].id,
          variant_section: this.p_details[i].vari_colors,
          selected_variant_id: this.p_details[i].colours.value,
        });
    }
  }

  // valid(){
  //   alert('test')
  // }
  logout() {
    this._accountService.logout().subscribe(() => {
      this.router.navigate(["/"], { queryParamsHandling: "merge" });
    });
  }

  remove_product_sku(index: number): void {
    this.p_sku._sku.splice(index, 1);
  }

  getProducts() {
    this.loading = true;
    var params = new ProductRequestParams({
      search: this.searchString,
      per_page: 20,
    });
    this._productService.getProducts(params).subscribe((p) => {
      for (let i = 0; i < p.products.length; i++) {
        if (i == 5) break;
        var variant_color = [];
        for (let j = 0; j < p.products[i].variants.edges.length; j++) {
          let data = {
            color:
              p.products[i].variants.edges[j].node.selectedOptions[0].value,
            variant_id: atob(p.products[i].variants.edges[j].node.id).slice(
              -14
            ),
          };
          variant_color.push(data);
        }
        this.p_details.push({
          name: p.products[i].title,
          amt: "$" + p.products[i].priceRange.maxVariantPrice.amount,
          des: p.products[i].description,
          img: p.products[i].images.edges[0].node.originalSrc,
          id: atob(p.products[i].variants.edges[0].node.id).slice(-14),
          colours: p.products[i].variants.edges[0].node.selectedOptions[0],
          vari_colors: variant_color,
        });
      }
      this.loading = false;
    });
  }

  changeQuantity(index: any, item_q: any) {
    this.p_sku._sku[index].value += item_q;
    if (this.p_sku._sku[index].value == 0) {
      this.p_sku._sku.splice(index, 1);
    }
  }

  changeVariant(index: any, ev) {
    //this.p_sku._sku[index]['selected_variant_id'] = ev.target.value
    this.p_sku._sku[index]["v_id"] = ev.target.value;
  }

  resetForm() {
    this.orderForm.reset();
    this.p_details = [];
    this.p_sku._sku = [];
    this.searchBox.nativeElement.value = "";
    this.warehouse = "";
    this.reason = "";
  }

  submit() {
    this.loading = true;
    const selected_items = this.p_sku._sku.map((value, item) => {
      const variantId = value.hasOwnProperty("v_id") ? value.v_id : value.id;
      return {
        variant_id: variantId,
        quantity: value.value,
        applied_discount: {
          description: "Custom discount",
          value_type: "percentage",
          value: "100.0",
          amount: value.amt,
          title: "Custom",
        },
      };
    });

    let data = {
      draft_order: {
        line_items: selected_items,
        shipping_address: {
          first_name: this.person_name,
          address1: this.shipping_info,
          phone: this.person_number,
          city: this.city,
          zip: this.pincode,
          province: this.state,
          country: this.country,
          last_name: this.last_name,
        },
        note: `Sample order\nWarehouse: ${this.warehouse}\nCreated by: ${this.user.displayName}, ${this.user.email},\nReason: ${this.reason}`,
        email: this.email,
        note_attributes: [
          {
            name: "warehouse",
            value: this.warehouse,
          },
        ],
        tags: this.reason,
        customAttributes: [
          {
            key: "warehouse",
            value: this.warehouse,
          },
        ],
      },
    };
    const url = environment.addOrder;
    this._ajaxService.ajaxPostWithBody(url, data).subscribe((res) => {
      this.loading = false;
      try {
        if (res.statusCode == 201) {
          alert("Order successfully placed.");
          this.resetForm();
        } else {
          alert("Oops ! Error creating order.");
          console.log(res?.error);
        }
      } catch (error) {
        console.log(error);
        alert("Oops ! Error creating order.");
      }
    });
  }

  ngOnInit(): void {
    this._accountService.getCurrentUser().subscribe((user) => {
      this.user = user;
    });
  }
}
