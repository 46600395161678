import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { FullCustomDimensionsSet } from './full-custom-dimensions-set';
import { DataLayerCustomDimensions } from './data-layer-custom-dimensions';


// This is a type that can be used for a partial version of the dimensions set,
// Where there are gaps and some dimensions are empty
export type PartialCustomDimensionsSet = { [key: string]: string[] | string | number[] | number };

@Injectable({
  providedIn: 'root'
})
export class DataLayerCustomDimensionsService {
  private _currentSet: FullCustomDimensionsSet;

  constructor(
    private _platform: Platform
  ) {
    this._generateEmptyDimensionsSet();
  }

  set dimensions(someDimensions: PartialCustomDimensionsSet) {
    Object.keys(DataLayerCustomDimensions).map(key => DataLayerCustomDimensions[key])
    .forEach(key => {
      this._currentSet[key] = someDimensions[key] || this._currentSet[key];
    });
  }

  trigger() {
    if (!this._platform.isBrowser) { return; }
    // (<any>window).dataLayer.push(this._currentSet);
  }

  private _generateEmptyDimensionsSet() {
    this._currentSet = {
      customerBillingAddress1: null,
      customerBillingAddress2: null,
      customerBillingCity: null,
      customerBillingCompany: null,
      customerBillingCountry: null,
      customerBillingEmail: null,
      customerBillingFirstName: null,
      customerBillingLastName: null,
      customerBillingPhone: null,
      customerBillingPostcode: null,
      customerFirstName: null,
      customerLastName: null,
      customerShippingAddress1: null,
      customerShippingAddress2: null,
      customerShippingCity: null,
      customerShippingCompany: null,
      customerShippingCountry: null,
      customerShippingFirstName: null,
      customerShippingLastName: null,
      customerShippingPostcode: null,
      ecomm_pagetype: null,
      ecomm_prodid: null,
      ecomm_totalvalue: null,
      pagePostType: null,
      pagePostType2: null,
      productIsVariable: null,
      productType: null,
      visitorEmail: null,
      visitorId: null,
      customerTotalOrderValue: null,
      customerTotalOrders: null,
      searchTerm: null,
      email: null,
      record_id: null
    };
  }
}
