import gql from 'graphql-tag';
import { fieldsForMediaTypes } from './product-by-handle.gql';

export const products = gql`
  ${fieldsForMediaTypes}
  query ($numProducts: Int!, $cursor: String, $filterStr: String, $sortKey: ProductSortKeys) {
    products(first: $numProducts, after: $cursor, query: $filterStr, sortKey: $sortKey) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          handle
          availableForSale
          compareAtPriceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          createdAt
          description
          descriptionHtml
          onlineStoreUrl
          vendor
          tags
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          productType
          publishedAt
          tags
          totalInventory
          updatedAt
          options(first: 3) {
            id
            name
            values
          }
          images(first: 100) {
            edges {
              node {
                id
                originalSrc
                altText
                transformedSrc
              }
            }
          }
          media(first: 100) {
            edges {
              node {
                ...fieldsForMediaTypes
              }
            }
          }
          variants(first: 15) {
            edges {
              node {
                id
                title
                compareAtPriceV2 {
                  amount
                }
                currentlyNotInStock
                selectedOptions {
                  name
                  value
                }
                priceV2 {
                  amount
                }
                image {
                  originalSrc
                  altText
                  transformedSrc
                }
                availableForSale
                quantityAvailable
                requiresShipping
                sku
                unitPrice {
                  amount
                }
              }
            }
          }
          hidden: metafield(key: "hidden", namespace: "seo") {
            value
          }
          metafields(first: 1) {
            edges {
              node {
                id
                key
                namespace
                value
                description
              }
            }
          }
        }
      }
    }
  }
`;