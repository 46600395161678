import gql from 'graphql-tag';

export const fieldsForMediaTypes = gql`
fragment fieldsForMediaTypes on Media {
  alt
  mediaContentType
  ... on Video {
    id
    sources {
      format
      height
      mimeType
      url
      width
    }
  }
  ... on ExternalVideo {
    id
    host  
    embeddedUrl
    previewImage {
      id
      originalSrc
      altText
      transformedSrc
    }
  }    
  ... on Model3d {      
    sources {
      format
      mimeType
      url
    }
  }    
  ... on MediaImage {      
    id   
    image {    
      altText
      originalSrc
    }
  }
}
`;

export const getProductByHandle = gql`
  ${fieldsForMediaTypes}
  query ($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      handle
      availableForSale
      compareAtPriceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      createdAt
      description
      descriptionHtml
      onlineStoreUrl
      vendor
      tags
      priceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      productType
      publishedAt
      tags
      totalInventory
      updatedAt
      options(first: 3) {
        id
        name
        values
      }
      images(first: 100) {
        edges {
          node {
            id
            originalSrc
            altText
            transformedSrc
          }
        }
      }
      media(first: 100) {
        edges {
          node {
            ...fieldsForMediaTypes
          }
        }
      }
      variants(first: 15) {
        edges {
          node {
            id
            title
            compareAtPriceV2 {
              amount
            }
            currentlyNotInStock
            selectedOptions {
              name
              value
            }
            priceV2 {
              amount
            }
            image {
              originalSrc
              altText
              transformedSrc
            }
            availableForSale
            quantityAvailable
            requiresShipping
            sku
            unitPrice {
              amount
            }
          }
        }
      }
      hidden: metafield(key: "hidden", namespace: "seo") {
        value
      }
      metafields(first: 1) {
        edges {
          node {
            id
            key
            namespace
            value
            description
          }
        }
      }
    }
  }
`;