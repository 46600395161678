import { Component, OnInit } from '@angular/core';

import { AccountService } from '../services/account.service';
import { AnalyticsService } from '../services/gtm/analytics.service';
import { CookieService } from '../services/cookie.service';
import { Md5 } from 'ts-md5';
import { Router } from '@angular/router';
import { User } from '../shared/models/user.model';
import { templateJitUrl } from '@angular/compiler';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  username:string = "";
  password:string = "";
  loginError:string = "";
  loading = false;
  popupMessage = "";
  registerError = "";
  showSystemChangePopup = false;

  get acceptsMarketing() {
    return this._acceptsMarketing;
  }
  set acceptsMarketing(m) {
    this._acceptsMarketing = m;
  }
  private _acceptsMarketing:boolean = true;
  constructor(public router: Router,
    private  _accountService :AccountService,
    private _analyticsService:AnalyticsService,
    private _cookieService: CookieService,
   ) { }


  login(){
   // this.router.navigateByUrl('orders')
   
      this.loginError = "";
      this.loading = true;
      if(this.username != '' && this.password != ''){
        this._accountService.login(this.username, this.password).subscribe(u=>{
          if (u instanceof User) {
        ///   this.handleRegister(this.username, this.password, this.acceptsMarketing,true);
           this.router.navigateByUrl('orders')
           // return;
        }else{
          this.loading = false;
          this.loginError = "There was an issue logging in, please check your username/password and try again";
        }
        });
      }else{
        this.loading = false;
          this.loginError = "There was an issue logging in, please check your username/password and try again";
      }
    }

  private handleRegister(user, pass, marketing, isLogin = false) {
    this.registerError = "";
    this.popupMessage = ""
    this.loading = true;

    const md5 = new Md5();

  //  this._analyticsService.eventTriggerWithRecord('emailOptIn', { 'record_id': md5.appendStr(this.username.toLowerCase()).end(),'email':this.username, 'registration_source':'sign_up'});

    this._accountService.register(user, pass, marketing).subscribe(u=>{
      if (!(u instanceof User))  {
        if (u && u.length > 0) {
          for (let err of u) {
            if (err.code == "CUSTOMER_DISABLED") {
              this.showSystemChangePopup = true;
              this.popupMessage = err.message;
              this.loading = false;
              return;
            }
          }
        }
        if (isLogin) {
          this.loginError = "There was an issue logging in, please check your username/password and try again";
        } else {
          this.registerError = "There was an issue while attempting to register the new user. If the problem persists please contact support.";
        }
      }
      this.loading = false;
    }, error => {
      console.log(error);
      this.loading = false;
      if (isLogin) {
        this.loginError = "There was an issue logging in, please check your username/password and try again";
      } else {
        this.registerError = "There was an issue while attempting to register the new user. If the problem persists please contact support.";
      }
    });
  }

  ngOnInit(): void {

  }

}