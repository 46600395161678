import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class AjaxService {

  constructor(private http:HttpClient) { }
  handleError = async (error: HttpErrorResponse) => {
    console.log("Orginal Error" + JSON.stringify(error));
    if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
    } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return null;
}
  httpOptionsWithJson = {
    headers: new HttpHeaders({ 'Authorization': 'Basic YWRtaW6YWRtaW4=', 'Content-Type': 'application/json;charset=utf-8' }),
  };

  private extractStringData(res: Response) {
    const body = res;
    return body || '';
 }

  ajaxPostWithBody(url: string, data: any): Observable<any> {
    return this.http.post(url, data, this.httpOptionsWithJson)
        .pipe(
            map(this.extractStringData),
            catchError(this.handleError)
        );
}
}
