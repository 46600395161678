import { PMDModel } from './pmdmodel.model';

export class ProductOption implements PMDModel {
    id: number;
    name: string;
    values: string[];

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }

      this.id = json.id;
      this.name = json.name;
      this.values = json.values;
    }
    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {
      var json: any = {
        'id': this.id,
        'name': this.name,
        'values': this.values,
      }
      return json;
    }
}
