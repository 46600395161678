
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';



import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';




@NgModule({
  declarations: [LoadingComponent],
  imports: [
    CommonModule,
    
    RouterModule,
    
    FormsModule,
    
  ],
  exports: [
    LoadingComponent,
  ],
  providers: [
    // RebuyEngineService
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CSRFInterceptor,
    //   multi: true
    // }
  ]
})
export class SharedModule { }
