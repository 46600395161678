import { environment as currentEnvironment, schemaEnvironment as currentSchemaEnvironment } from './current-environment';

export const environment = {
  hschatid: '10b2d699-77b0-4f87-baeb-b14bcfeb273a',
  hsemailid: '0319c3fb-9cf3-4e39-844b-2674665a7590',
  hsnormal: '10b2d699-77b0-4f87-baeb-b14bcfeb273a',
  hsesthetician: '2c70c7d9-a3da-4df9-ae20-3054b665a972',
  rebuyapikey: 'c341c4eefb53ba062ee580ff2cc8b81ed6e5b103',
  shopifyURL: 'https://pmdb.myshopify.com',
  // rebuyRecommendationUrl: 'https://rebuyengine.com/api/v1/products/recommended',
  rebuyRuleId: '6172',
  rebuyCartRuleId: '8800',
  rebuyRecommendationUrl: 'https://rebuyengine.com/api/v1/custom/id/',
  cartUpsell: 'cart_upsell',
  blogPost: 'blog_post',
  promoBarId: 'promo_bar',
  currentSite: 'us',
  apiURL: 'https://checkout.pmdbeauty.com',
  prismicUrl: 'https://pmd-cms.cdn.prismic.io/api/v2',
  prismicAccessToken: 'MC5YeEZ4Q2hFQUFDVUFieDlp.Tnbvv70XDu-_vUDvv71s77-9IhXvv73vv71r77-9KO-_ve-_ve-_vWMa77-977-977-9Su-_vUJh77-9G--_vQ',
  serviceAPIURL: 'https://apiservice.pmdbeauty.com',
  
  rechargeAPIUrl: 'https://api.rechargeapps.com',
  ppCID: 'ATmCGfjsMuByeEVR4PcHMmb0ITGjwLcMdPKjmImLB1w2in9-XJDI6LnJf4K8mXFy1Gc4s1r1vew6D1TO',
  yotpoKey: 'KCrsVRHisnU8eqk59Mifdgb8LrP5pj5zjeTwl994',
  apJSURL: 'https://portal.sandbox.afterpay.com/afterpay.js',
  protocol: 'http',
  host: 'samples.pmdbeauty.com',
  cookieDomain: 'localhost',
  defaultUpsellSlug: 'refinish-acid-serum-exclusive-offer',
  sentryDSN: "https://2b940109862b4b11ae6d5fbe57ce3bbf@o987946.ingest.sentry.io/5945024",
  stripePK: 'pk_test_dkemSfAhbay9DBQ5wtP2Y8L900riF9xVkV',
  underConstructionVendor: 'https://www.amazon.com/stores/node/10011675011/ref=dp_csx_lgbl_n_luxury-beauty',
  getClientIP: 'https://api.ipify.org?format=json',
  getCustomerRegion: 'https://api.ipregistry.co/',
  ipapiAcKey: '20jh41sva5milf',
  gmapApiKey: "AIzaSyBaWtZM9i6q3l76p7Mp-I1o2VWfC7WpsF4",
  production: false,
  currencySymbol: '$',
  graphqlURL: 'https://pmdb-ff.myshopify.com/api/2021-10/graphql',
  //graphqlURL: 'https://abcdefgh1213.myshopify.com/api/2021-10/graphql',
  graphqlHeader: {
    'X-Shopify-Storefront-Access-Token': '8eb0f41ee01272b25da9b71e1a26504d',
    'Accept': 'application/json',
  },
// addOrder: 'http://localhost:5421/users/create'
addOrder: 'https://samples-api.pmdbeauty.com/users/create'
};

export const schemaEnvironment = {
  url: environment.protocol + '://' + environment.host,
  logo: {
    url: "https://pmdbeauty.com/wp-content/uploads/2018/01/pink_logo_.jpg",
    width: 300,
    height: 152,
    caption: "PMD"
  },
  sameAs: [
    "https://www.facebook.com/PMDPersonalMicroderm",
    "https://instagram.com/pmdpersonalmicroderm/",
    "https://www.linkedin.com/company/pmd-personal-microderm?trk=top_nav_home",
    "https://www.youtube.com/user/pmd",
    "https://www.pinterest.com/pmdmicroderm/",
    "https://twitter.com/personalmicrodm"
  ],
  name: "PMD Beauty",
  description: "PMD Beauty Personal Microderm",
  currency: "USD",
  ...currentSchemaEnvironment
}