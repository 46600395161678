import { PMDModel } from './pmdmodel.model';
import { ProductDimensions } from './productdimensions.model';
import { ProductCategory } from './productcategory.model';
import { ProductImage } from './productimage.model';
import { ProductAttributeOptions } from './productattributeoptions.model';
import { ProductAttribute } from './productattribute.model';
import { ProductVariation } from './productvariation.model';
import { MetaData } from './metadata.model';
import { ProductPriceRange } from './productpricerange.model';
import { ProductOption } from './productoption.model';
import { Buffer } from 'buffer';
import { ShopifyEdge } from './shopifyedge';
import { SubscriptionOption } from './subscription-option.model';
import { ProductMedia } from './productmedia.model';


export class Product implements PMDModel {

  availableForSale: boolean;
  compareAtPriceRange: ProductPriceRange;
  createdAt: Date;
  description: string;
  descriptionHtml: string;
  handle: string;
  id: string;
  images: {edges:ShopifyEdge<ProductImage>[]};
  media: {edges: ShopifyEdge<ProductMedia>[]};
  metafields: any;
  onlineStoreUrl: string;
  priceRange: ProductPriceRange;
  productType: string;
  publishedAt: Date;
  tags: string[];
  title: string;
  totalInventory: number;
  updatedAt: Date;
  variants: {edges:ShopifyEdge<ProductVariation>[]};
  options: ProductOption[];
  vendor: string;
  decodedId: string;
  isMultiVariant: boolean;
  purchaseType: string;
  subscriptionOption: SubscriptionOption;
  hidden: boolean;

  /**
   * Create a Call.
   * @param {any} json - The JSON object with which to construct our call.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Call.
   * @param {any} json - The JSON object with which to load our call.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.handle = json.handle;
    this.availableForSale = json.availableForSale;
    this.compareAtPriceRange = new ProductPriceRange(json.compareAtPriceRange);
    this.createdAt = new Date(json.createdAt);
    this.description = json.description;
    this.descriptionHtml = json.descriptionHtml;
    this.onlineStoreUrl = json.onlineStoreUrl;
    this.priceRange = new ProductPriceRange(json.priceRange);
    this.productType = json.productType;
    this.publishedAt = new Date(json.publishedAt);
    this.tags = json.tags;
    this.title = json.title;
    this.totalInventory = json.totalInventory;
    this.updatedAt = new Date(json.updatedAt);
    this.vendor = json.vendor;
    this.variants = {edges: json.variants && json.variants.edges && json.variants.edges.length > 0 ? json.variants.edges.map(edge=>new ShopifyEdge<ProductVariation>(edge, ProductVariation)):[]};
    this.images = {edges: json.images && json.images.edges && json.images.edges.length > 0 ? json.images.edges.map(edge=>new ShopifyEdge<ProductImage>(edge, ProductImage)):[]};
    this.media = {edges: json.media && json.media.edges && json.media.edges.length > 0 ? json.media.edges.map(edge => new ShopifyEdge<ProductMedia>(edge, ProductMedia)):[]};
    this.metafields = json.metafields;
    this.options = json.options && json.options.length > 0 ? json.options.map(option => new ProductOption(option)):[];
    // this property is added to get the decoded id 
    // decoded id is required for sending the product id to the rebuy recommendation engine
    // it does not require data in the base64 format
    this.decodedId = this.getDecodedId();
    this.purchaseType = json.purchaseType;
    this.isMultiVariant = json.isMultiVariant;
    this.subscriptionOption = json.subscriptionOption? json.subscriptionOption : new SubscriptionOption({
      "order_interval_frequency": 0,
      "order_interval_unit": "",
    });
    this.hidden = json.hidden?.value === "1";
  }

  productoptionsFromJSON(options: any[]) {
    let opts: ProductOption[] = [];
    for (let option of options) {
      opts.push(new ProductOption(option));
    }
    return opts;
  }


  /**
   * Returns a JSON representation of our call
   * @return {any} - The JSON representation of our call
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'handle': this.handle,
      'availableForSale': this.availableForSale,
      'compareAtPriceRange': this.compareAtPriceRange ? this.compareAtPriceRange.toJSON() : null,
      'createdAt': this.createdAt ? this.createdAt.toISOString() : null,
      'description': this.description,
      'descriptionHtml': this.descriptionHtml,
      'onlineStoreUrl': this.onlineStoreUrl,
      'priceRange': this.priceRange ? this.priceRange.toJSON() : null,
      'productType': this.productType,
      'publishedAt': this.publishedAt ? this.publishedAt.toISOString() : null,
      'tags': this.tags,
      'title': this.title,
      'totalInventory': this.totalInventory,
      'updatedAt': this.updatedAt ? this.updatedAt.toISOString() : null,
      'vendor': this.vendor,
      'variants': {edges: this.variants && this.variants.edges && this.variants.edges.length > 0 ? this.variants.edges.map(edge=>edge.toJSON()):[]},
      'images': {edges: this.images && this.images.edges && this.images.edges.length > 0 ? this.images.edges.map(edge=>edge.toJSON()):[]},
      'media': {edges: this.media && this.media.edges && this.media.edges.length > 0 ? this.media.edges.map((edge)=>edge.toJSON()): []},
      'metafields': this.metafields,
      'options': this.options ? this.options.map(option=>option.toJSON()):[],
      'isMultiVariant': this.isMultiVariant,
      'purchaseType': this.purchaseType,
      'subscriptionOption': this.subscriptionOption,
    };
    return json;
  }

  arrayToJSON(arr:PMDModel[]) {
      let ret: any[] = [];
      for (let val of arr) {
          ret.push(val.toJSON());
      }
      return ret;
  }

  getMeta(key:string):MetaData {
    if (!this.metafields || this.metafields.edges.length <= 0 ) return null;
    // if (!this.metafield) return null;
    for (let data of this.metafields.edges) {
      if (data.node.key == key) return data;
    }
    return null;
    // if (this.metafield.key === key) return this.metafield;
    // return null;

  }

  variationOutOfStock(attribute:any, option:string): boolean {
    if (this.variants.edges.length > 0) {
      let optionInStock = false;
      for( let variation of this.variants.edges) {
        let atts = variation.node.selectedOptions.filter(a=> a.name == attribute.name && a.value == option);
        if (atts.length > 0) {
          optionInStock = optionInStock || variation.node.availableForSale;
        }
      }
      return !optionInStock;
    }
    return !this.availableForSale;
  }

  getDecodedId() {
    if (!this.id) return null;
    let id = Buffer.from(this.id, 'base64').toString();
    id = id.split('/').pop();
    return id
  }
}
