import { PMDModel } from './pmdmodel.model';
import { Product } from './product.model';
import { ProductDimensions } from './productdimensions.model';
import { ProductImage } from './productimage.model';
import { ProductAttribute } from './productattribute.model';
import { ProductSelectedOption } from './productselectedoption.model';
import { ProductUnitPriceMeasurement } from './productunitpricemeasurement.model';
import { MetaData } from './metadata.model';
import { MoneyV2 } from './moneyv2.model';
import { Buffer } from 'buffer';

export class ProductVariation implements PMDModel {

    id: string;
    availableForSale: boolean;
    compareAtPriceV2: MoneyV2;
    currentlyNotInStock: boolean;
    image: ProductImage;
    priceV2: MoneyV2;
    quantityAvailable: number;
    requiresShipping: boolean;
    selectedOptions: ProductSelectedOption[];
    sku: string;
    title: string;
    unitPrice: MoneyV2;

    /**
     * Create a Call.
     * @param {any} json - The JSON object with which to construct our call.
     */
    constructor(json:any) {
      this.loadWithJSON(json);
    }
    /**
     * Load a Call.
     * @param {any} json - The JSON object with which to load our call.
     */
    loadWithJSON(json: any) {
      if (!json) {
        return;
      }
      this.id = json.id;
      this.availableForSale = json.availableForSale;
      this.compareAtPriceV2 = json.compareAtPriceV2;
      this.currentlyNotInStock = json.currentlyNotInStock;
      this.image = new ProductImage(json.image);
      this.priceV2 = json.priceV2;
      this.quantityAvailable = json.quantityAvailable;
      this.requiresShipping = json.requiresShipping;
      this.selectedOptions = this.optionsFromJSON(json.selectedOptions);
      this.sku = json.sku;
      this.title = json.title;
      this.unitPrice = json.unitPrice;

      
    }

    optionsFromJSON(selectedOptions: any[]) {
      let options: ProductSelectedOption[] = [];
      for (let opt of selectedOptions) {
        options.push(new ProductSelectedOption(opt));
      }
      return options;
    }

    /**
     * Returns a JSON representation of our call
     * @return {any} - The JSON representation of our call
     */
    toJSON() {

      var json: any = {
        'id': this.id,
        'availableForSale': this.availableForSale,
        'compareAtPriceV2': this.compareAtPriceV2,
        'currentNotInStock': this.currentlyNotInStock,
        'image': this.image ? this.image.toJSON() : null,
        'priceV2': this.priceV2,
        'quantityAvailable': this.quantityAvailable,
        'requiresShipping': this.requiresShipping,
        'selectedOptions': this.arrayToJSON(this.selectedOptions),
        'sku': this.sku,
        'title': this.title,
        'unitPrice': this.unitPrice,
      }
      return json;
    }

    // TODO
    arrayToJSON(arr:PMDModel[]) {
      let ret: any[] = [];
      for (let val of arr) {
        ret.push(val.toJSON());
      }
      return ret;
    }

    getURLQueryParams() {
      let params = {};
      for (let att of this.selectedOptions)  {
        params['attribute_' + att.name.toLowerCase().replace(/\s/g, '-')] = att.value;
      }
      return params;
    }

    getDecodedId() {
      if (!this.id) return null;
      let id = Buffer.from(this.id, 'base64').toString();
      id = id.split('/').pop();
      return id
    }
}
